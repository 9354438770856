import React, { useContext } from 'react'
import styled from 'styled-components'
import { H2, H3, P } from '../../../styles/components/HomeText'
import { Card, Cta_, Focus_, device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { hasQuestAdSubscription } from '../utils'
import { AuthContext } from '../../../services/authentication/Guard'

const HomeHow = styled.section`

    button{
        margin: 4rem auto 0 auto;
    }
`

const HomeHowGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;

    @media ${device.xl} {
        grid-template-columns: 1fr;
    }
`

const HomeHowCard = styled(Card)`
    /* display: flex;
    gap: 2rem; */
    padding: 2rem 4rem;

    h3{
        color: var(--focus);
    }

    aside{
        h4{
            font-size: 15rem;
            color: var(--focus);
        }
    }

    main{
        margin-top: 3.5rem;
    }
`

const HomeHowCardLast = styled(HomeHowCard)`

    .HomeHowCardSplit{
        /* display: flex;
        gap: 2rem; */
    }
`

const HomeHowAction = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function Home_How(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const auth = useContext(AuthContext);

  return (
    <HomeHow>
        <H2>{t("How it works")}</H2>
        <HomeHowGrid>
            <HomeHowCard>
                <aside>
                    <h4>1</h4>
                </aside>
                <main>
                    <H3>{t("Connect")}</H3>
                    <P>{t("Our app starts by getting to know your business and goals. Answer a few simple questions, and our algorithm will generate tailored campaign options just for you.")}</P>
                </main>
            </HomeHowCard>
            <HomeHowCard>
                <aside>
                    <h4>2</h4>
                </aside>
                <main>
                    <H3>{t("Create")}</H3>
                    <P>{t("Once you've answered the questionnaire, it's time to bring your campaign to life. Use our user-friendly ad builder to create stunning ads that resonate with your audience.")}</P>
                </main>
            </HomeHowCard>
            <HomeHowCardLast>
                <div className='HomeHowCardSplit'>
                    <aside>
                        <h4>3</h4>
                    </aside>
                    <main>
                        <H3>{t("Run ad")}</H3>
                        <P>{t("With just a few clicks, you can launch your campaign and start reaching your target audience. Our app leverages advanced algorithms to analyze your ad spend and determine the most effective strategy for running ads, ensuring maximum impact.")}</P>
                    </main>
                </div>
            </HomeHowCardLast>
        </HomeHowGrid>
        <Cta_ size="large" text={t("Start Now")} onClick={()=> hasQuestAdSubscription(navigate, auth)}/>
    </HomeHow>
  )
}
