import { Cta_ } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { hasQuestAdSubscription } from '../utils'
import { AuthContext } from '../../../services/authentication/Guard'
import { useNavigate } from 'react-router-dom'

const HomeLast = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
   // border: 1px solid  ${(props) => props.theme.mid};
   // padding: 6rem;
   height: 50vh;
`

const HomeLastGlow = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
   // position: absolute;
  /* top: calc(50% - 150px);
  left: calc(50% - 150px); */
  /* width: 65rem;
  height: 65rem;
  border-radius: 50%;
  box-shadow:
    inset 0 0 50px var(--white),
    inset 20px 0 80px var(--focus),
    inset -20px 0 80px var(--main),
    inset 20px 0 300px var(--focus),
    inset -20px 0 300px var(--main),
    0 0 50px var(--white),
    -10px 0 80px var(--focus),
    10px 0 80px var(--main); */

   
`
const HomeLastText = styled.div`

   h1{
       // color: ;
        text-align: center;
        font-size: 6rem;
        padding-bottom: 2rem;
    }
    button{
     margin: 0 auto;
    }

    img{
      width: 40rem;
      margin: 0 auto;
    }
    .gradientText{
        text-align: center;
        background-color: #f3ec78;
        background-image: radial-gradient(47.08% 208.33% at 79.71% 128.33%, rgba(217,89,183, 0.8) 11.69%, rgba(52,113,117, 0.8) 35.44%,rgba(247,247,247, 0.8)  70.24%);
        /* background-image: linear-gradient(90deg, var(--gloom), var(--main) 10%, var(--gloom)); */
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
}
`
const HomeLastWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`





export const Home_Last = () => {
  
  const {t} = useTranslation();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  
  return (
    <HomeLast>
           
    
      {/* <HomeLastWrapper> */}
        {/* <HomeLastGlow> */}
 
          <HomeLastText>
            <h1 className='gradientText'>Be part of the future of Questad</h1>
      
            <Cta_ text={t('Start now')} size='large' onClick={() => hasQuestAdSubscription(navigate, auth)}/>

            {/* <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/TimeSaving.svg' /> */}
            </HomeLastText>
        {/* </HomeLastGlow> */}
        {/* </HomeLastWrapper> */}
    </HomeLast>
  )
}
