import React, { useContext } from 'react'
import styled from 'styled-components'
import { H2, P } from '../../../styles/components/HomeText'
import { useTranslation } from 'react-i18next';
import { Cta_, H5, IconSvg, device } from 'monica-alexandria';
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg';
import { useNavigate, useParams } from 'react-router-dom';
import { hasQuestAdSubscription } from '../utils';
import { AuthContext } from '../../../services/authentication/Guard'

const HomeAdSpend = styled.section`
 

`
const HomeAdSpendGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: space-between;

  grid-gap: 20rem;

  @media ${device.md} {
    grid-template-columns: 1fr !important;
    grid-gap: 6rem;
  }


  @media ${device.lg} {
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }
`
const HomeAdSpendImg = styled.div`

 img{
        max-width: 40rem;
        margin: 0 auto;
        display: block;
    }


`

const HomeAdSpendText = styled.div`
  padding-right: 15rem ;
  h1{
    font-size: 5rem;
    line-height: 1;

  }
  border-radius: 20px;
  transition: 700ms;

h1{
  padding-bottom: 2rem;
}
  
@media ${device.md} {
  padding-right: 0rem ;
  }

  
`


const HomeHelp = styled.div`
    padding-top: 6rem;
    h2{
        /* text-align: center; */
        //color: var(--main);
    }
`

const HomeHelpWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding-bottom: 4rem;

    @media ${device.md}{
        grid-template-columns: 1fr 1fr ;
    }

`

const HomeHelpBox = styled.div`

    padding: 4rem;
    border-radius: var(--normalRadius);
    box-shadow: ${p => p.theme.out};
    background: ${p => p.theme.mid};
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    align-content: start;
  

    @media ${device.lg}{
        grid-template-columns: 1fr  ;
    }

    svg{
        fill: ${p => p.theme.color};
        width: 2.5rem;

    }
  
`
const HomeHelpAction = styled.div`
    padding-top: 3rem;

`
export default function Home_AdSpend() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const auth = useContext(AuthContext);

  return (
    <HomeAdSpend>
   <HomeAdSpendGrid>
      <HomeAdSpendText>
        <H2 className='gradientText'>{t('Strategic')}<br/>{t('Ad Spend Allocation')}</H2>
        {/* <H5>{t("Running successful ad campaigns on social media requires more than just setting a budget. It's about strategically allocating your ad spend to reach your target audience and achieve your campaign objectives. That's where our app comes in. Our app uses a sophisticated algorithm to analyze your ad spend and determine the most effective strategy for running ads. By inputting your ad spend, our algorithm takes into account various factors such as your target audience, campaign objectives, and historical data to create a tailored strategy that maximizes your ad spend.")}</H5> */}
        <H5>{t('Our app optimizes social media ad campaigns by analyzing your answers on the questionnaires. Depending on your main sales objective & budget Questad tailors strategies to reach your target audience and goals effectively. Using advanced algorithms, it considers factors like audience demographics and past performance to maximize your ad spend.')}</H5>
        <HomeHelpAction>
            <Cta_ text={t('Start Now')}  onClick={()=> hasQuestAdSubscription(navigate, auth) } size='large'/>
        </HomeHelpAction>   
      </HomeAdSpendText>
     
      <HomeAdSpendImg>
         <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/TimeSaving.svg' />
      </HomeAdSpendImg>
      </HomeAdSpendGrid>

      {/* <HomeHelp>
        <HomeHelpWrapper>
            <HomeHelpBox>
              <DailyprofitSvg Icon='Automatic' />
                <H5>{t("Automate your social media marketing in few clicks.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <IconSvg Icon='Rocket' />
                <H5>{t("Tailored campaigns to suit your business needs.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <DailyprofitSvg Icon='Roi' />
                <H5>{t("Data-driven strategies for maximum ROI.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <IconSvg Icon='Xicon' />
                <H5>{t("No digital agency or marketeer needed.")}</H5>
            </HomeHelpBox>
           
        </HomeHelpWrapper>
     
    </HomeHelp> */}
    </HomeAdSpend>
  )
}
