import React, { useEffect } from 'react';
import styled from 'styled-components';
import { H2, H3, P } from '../../../styles/components/HomeText';
import { Card, Focus_, Focus_Plain, Grey_Link, Main_, device } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const HomeFeatures = styled.section`
  
    button{
        margin-top: 4rem;
    }

    h2{
      //  padding-top: 6rem;
    }
`;

const HomeFeaturesBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    grid-gap: 4rem;
    padding: 4rem;
    margin-bottom: 15rem;

    img{
        max-width: 50rem;
        margin: 0 auto;
        display: block;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 4rem;
    }

    main{

        button{
            margin-top: 4rem;
        }
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

const HomeFeaturesBoxInverted = styled(HomeFeaturesBox)`
    grid-template-columns: 2fr 1fr;

    @media ${device.md} {
        grid-template-columns: 1fr;

        img{
            grid-row-start: 1;
        }
    }
`;

export default function Home_Features(props) {

    const {t} = useTranslation();

    return (
        <HomeFeatures>
            {/* <H2>{t("Curious to Learn More? ")}</H2>
                <P>{t("Get a sneak peek into the future of your social media")}</P>
                <P>{t("success with a hands-on demo of our app's features and benefits")}.</P>
                <Main_ text={t('Request a demo')} size='large' /> */}
            <H2>{t("Transform Your Business")}<br/> {t("with Questad's")}<br/>{t("Effortless Ad Expertise!")}</H2>
        </HomeFeatures>
    );
}
