import { IconSvg, device } from 'monica-alexandria'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { Background_Detector } from './Background_Detector'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'


const InstagramMockupWrapper = styled.div`
    aspect-ratio: 9/16;
    background-image: linear-gradient(180deg, var(--greyDark) 0%, transparent 50%, var(--greyDark) 100%);
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    max-width: 40rem; //normal
    width: 100vw; //temp


    @media ${device.sm}{
       max-width: 35rem;
    }

`

const AdMockButton = styled.div`
    background-color: var(--white);
    width: fit-content;
    border-radius: var(--normalRadius);
    padding: var(--smallPads);
    opacity: 0.7;
    display: flex;
    align-self: center;
    h5{
        color: var(--black);
    }

`

const AdMockupAccount = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 1rem;

    h1{
        color: var(--white);
    }
 
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    h6{
        color: var(--white);
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
    }

    p{
        font-size: 1.3rem;
        color: var(--white);
    }
`

const AdMockupAccountActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    svg{
        width: 1.8rem;
        height: 1.8rem;
        fill: var(--white);
    }
`

const AdMockupImage = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    img{
        height : 100% ;
        width: 100%;
        object-fit: contain;
    }
`

const AdMockupTop = styled.div`
    padding-top: 1rem;
    width: 100%;
    position: absolute;
    top: 0;
    
    `

const AdMockupBottomWrapper = styled.div`
    position: absolute; 
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`

const AdMockupBottom = styled.div`

      display: flex;
      flex-direction: column;
      align-items: center;


    h2{
        text-align: center;
        color: var(--white);
        opacity: 0.7;
    }
`

const AdMockupLine = styled.div`
    width: 98%;
    height: 3px;
    margin: 0 .5rem;
    position: relative;
    overflow: hidden;
    background-color: #D0D0D0;
    border-radius: 0.3rem;

    &::before{
        content: "";
        position: absolute;
        left: -50%;
        height: 3px;
        width: 80%;
        border-radius: 0.3rem;
        background-color: var(--white);

    }
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Instagram_Mockup_Stories = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const {questId} = useParams();

  return (
    <InstagramMockupWrapper>
        <AdMockupTop>
            <AdMockupLine></AdMockupLine>
            <AdMockupAccount>
                <AdMockupAccountInfo>
                    <img src={questId ? props?.adInfo?.facebookPagePicture :facebook_page?.picture} />
                    <div>
                        <h6>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h6>
                        <p>Sponsored</p>
                    </div>
                </AdMockupAccountInfo>
                <AdMockupAccountActions>
                    <DailyprofitSvg Icon='InstaOptions' />
                    <IconSvg Icon='Xicon'/>
                </AdMockupAccountActions>
            </AdMockupAccount>
        </AdMockupTop>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                    //config={facebookConfig}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupBottomWrapper>
            <AdMockupBottom>
                <h2>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h2>
                <AdMockButton>
                    <h5>{findLabelByValue(props.adInfo?.cta, t)}</h5>
                </AdMockButton>
            </AdMockupBottom>
        </AdMockupBottomWrapper>
    </InstagramMockupWrapper>
  )
}
