import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import store from '../../redux/store'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
import { AdMockButton, AdMockupActions, AdMockupCaption, AdMockupCarouselImg, AdMockupCarouselItem, AdMockupCarouselText, AdMockupImage, AdMockupPage, AdMockupSection, AdMockupTitle } from '../../styles/components/AdMockup'
import { AlertContext, device, Main_Text_Loader } from 'monica-alexandria';
import Fb_Carousel from '../../library/fbCarousel/Fb_Carousel';
import q from '../../services/api/Quests';
import { alertMessage } from '../../utils/messagesAlerts';
import { Carousel_ } from '../questionnaireB/Carousel_';

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const PreviewAdMockupAnimation = keyframes`
  0% {
    box-shadow: -3px -3px 10px var(--main);
  }
  25% {
    box-shadow: 3px -3px 10px var(--main);
  }
  50% {
    box-shadow: 3px 3px 10px var(--main);
  }
  75% {
    box-shadow: -3px 3px 10px var(--main);
  }
  100% {
    box-shadow: -3px -3px 10px var(--main);
  }
`;

const PreviewCatalogMockup = styled.div`
  max-width: 50rem;
  width: 100%;
  padding-bottom: 1rem;
  box-shadow: ${p => p.theme.out};
  border-radius: var(--smallRadius);
  animation: ${PreviewAdMockupAnimation} 4s infinite linear;
  margin-top: 2rem;


  @media ${device.sm}{
      width: 30rem;
  }

  .carousel.carousel-slider .control-arrow{
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    background: var(--greyDark) !important;
    margin: 1rem;
    margin-top: 50%;
    transform: translate(0%, -50%);
  }

  .slide{
    min-width: calc(100% - 20rem) !important;
    margin: .5rem !important;
    transform: translateX(20rem);
  }
`

export default function Preview_Catalog_Mockup(props) {

    const {t} = useTranslation();

    const {alerts, setAlerts} = useContext(AlertContext);
    const [loading, setLoading] = useState(false)

    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;


    console.log('adInfo products', props.adInfo)
    console.log('props.products', props.products)

  return (
    <PreviewCatalogMockup>
      <AdMockupPage>
        <img src={props?.questIdInUrl ? props?.adInfo?.facebookPagePicture : facebook_page?.picture} />
        <div>
            <h3>{props?.questIdInUrl ? props?.adInfo?.facebookPageName : facebook_page?.name}</h3>
            <p>{t('Sponsored')}</p>
        </div>
      </AdMockupPage>
      <AdMockupCaption>
          <h5>{props?.adInfo?.caption}</h5>
      </AdMockupCaption>
      <AdMockupImage>
        <Carousel_ refreshWidth={Object.keys(props?.products || [])}>
          <Fb_Carousel adInfo={props?.adInfo} products={props?.products} loading={loading}/>
        </Carousel_> 
      </AdMockupImage>
      <AdMockupActions>
        <h6>{t('Like')}</h6>
        <h6>{t('Comment')}</h6>
        <h6>{t('Share')}</h6>
      </AdMockupActions>
  </PreviewCatalogMockup>
  )
}
