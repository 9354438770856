import React from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H2, H5, P, device } from 'monica-alexandria';
import sophie from '../../../assets/home/sophie.webp'
import zoe from '../../../assets/home/zoe.jpg'
import katerina from '../../../assets/home/katerina.jpg'

const HomeTestimonials = styled.section`
/* 
  h2{
    grid-column: span 2;
  }

  @media ${device.lg} {
        h2{
            grid-column: span 1;
        }
    } */
`;

const HomeTestimonialsBottomSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6rem;

    @media ${device.lg} {
        grid-template-columns: 1fr;
        grid-gap:4rem;

    }

    @media ${device.md} {
        grid-gap: 2rem;
    }

`

const HomeTestimonialsBox = styled.div`
    display: flex;
    flex-direction: column;
    /* /height: 35rem; */
    //justify-content: space-between;

    padding: 4rem;
    border-radius: var(--normalRadius);
    box-shadow: ${p => p.theme.out};
    
    /* h4{
        margin-top: 4rem;
    } */
`

const HomeTestimonialsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    /* align-items: center; */


    @media ${device.md} {
        grid-template-columns: 1fr;
    }
   

` 

const HomeTestimonialsBoxImg = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;


    img{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        object-fit: cover;
    }

`

export const Home_Testimonials = () => {
    const {t} = useTranslation();
  return (
    <HomeTestimonials>    
        <HomeTestimonialsWrapper>
            <H2>{t('The Art of Simplifying')} <br/>{t('Social Media Success')}</H2>
            <HomeTestimonialsBox>
                <HomeTestimonialsBoxImg>
                    <img src={katerina} />
                    <div>
                        <h4>{t('Katerina Dysseaki')}</h4>
                        <p>Gata Negra Spanish Online</p>
                    </div>
                </HomeTestimonialsBoxImg>
                <H5>"{t("I have no words for how much Questad has helped me with my Facebook campaign as a Spanish teacher. The first campaign I did for a month with €50, brought me 27 contacts with prospective students. I had done the exact same campaign (verbal, video, targeting and €60/30 days) immediately before my collaboration with Doitforme alone, through the Facebook platform, resulting in 4 spam messages, about 30 likes from random names and none(!) contact with a prospective student. Now 3 days ago I did a second campaign with 21 contacts so far!! -this time alone, without the help of Luigi, who was very helpful the first time, step by step, via video call he guided me to set it up. I highly recommend working with Doitforme! It is an investment in the growth of any business, especially for an individual service provider like mine.")}"</H5>
            </HomeTestimonialsBox>
            <HomeTestimonialsBox>
                
                <HomeTestimonialsBoxImg>
                    <img src={zoe} />
                    <div>
                        <h4>{t('Zoe Papandreou')}</h4>
                        <p>La tienda de la vita</p>
                    </div>
                </HomeTestimonialsBoxImg>
                <H5>"{t("I started running ads for my eshop through Questad. I saw a difference in results from the very first month. My turnover skyrocketed and because of the results reports the app sends every week I was able to accurately measure my refund on advertising expense.")}"</H5>
            </HomeTestimonialsBox>
            <HomeTestimonialsBox>
                <HomeTestimonialsBoxImg>
                    <img src={sophie} />
                    <div>
                        <h4>{t('Sophia Diamantea')}</h4>
                        <p>Sophie’s Lemonade</p>
                    </div>
                </HomeTestimonialsBoxImg>
                <H5>"{t("Questad has solved all my problems in finding new clients. It saved me valuable time and I managed to have clients in areas that I would not have been able to reach easily. It also helped me to get to know my work a lot more people than if I had done an advertisement on social media by myself. Very easy to use, impressively efficient and excellent technical support from the questad team. Extra bonus tips and guidance for more effective ads!")}"</H5>
            </HomeTestimonialsBox>
        </HomeTestimonialsWrapper>

    </HomeTestimonials>
  )
}
