import { IconSvg, device } from 'monica-alexandria'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { Background_Detector } from './Background_Detector'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'


const InstagramMockupWrapper = styled.div`
    aspect-ratio: 9/16;
    background-image: linear-gradient(180deg, var(--greyDark) 0%, transparent 50%, var(--greyDark) 100%);
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    max-width: 40rem; //normal
    width: 100vw; //temp


    @media ${device.sm}{
       max-width: 35rem;
    }

`

const AdMockButton = styled.div`
    background-color: #fff;
    width: fit-content;
    border-radius: var(--normalRadius);
    padding: var(--normalPads);
    margin-top: 1rem;
    display: flex;
    align-self: center;
    h5{
        color: var(--black);
    }

`

const AdMockupAccount = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 1rem;

    h1{
        color: var(--white);
    }
 
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    h6{
        color: var(--white);
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
    }

    p{
        font-size: 1.3rem;
        color: var(--white);
    }
`

const AdMockupAccountActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    svg{
        width: 1.8rem;
        height: 1.8rem;
        fill: var(--white);
    }
`

const AdMockupImage = styled.div`
    /* padding: 0 3rem; */
    background-color: #fff;
    border-radius: var(--smallRadius);
    overflow: hidden;
    img{
        height : 100% ;
        width: 100%;
        object-fit: contain;
       //padding: 1rem;
    }

    h4{
        padding: 1rem;
        color: var(--black);
    }
`
const AdMockupImageWrapper = styled.div`
    padding: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    
  
`
const AdMockupText =  styled.div`
    padding: 1rem;
`

const AdMockupTop = styled.div`
    padding-top: 1rem;
    width: 100%;
    position: absolute;
    top: 0;
    
    `

const AdMockupBottomWrapper = styled.div`
    position: absolute; 
   // top: 50%;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    svg{
        width: 1.3rem;
        transform: rotate(90deg);
        fill: #fff;
    }
`

const AdMockupBottom = styled.div`

      display: flex;
      flex-direction: column;
      align-items: center;


    h2{
        text-align: center;
        color: var(--white);
        opacity: 0.7;
    }
`

const AdMockupLine = styled.div`
    width: 98%;
    height: 3px;
    margin: 0 .5rem;
    position: relative;
    overflow: hidden;
    background-color: #D0D0D0;
    border-radius: 0.3rem;

    &::before{
        content: "";
        position: absolute;
        left: -50%;
        height: 3px;
        width: 80%;
        border-radius: 0.3rem;
        background-color: var(--white);

    }
`

const InfoDetails = styled.div`
 display: flex;
 align-items: center;
 svg{
    width: 1rem;
    fill: var(--white);
 }
`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Instagram_Product_Stories = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const getFirstImageUrl = (imageUrl) => {
        return imageUrl?.split(',')[0].trim();
    };

    console.log('props.products', props.products, props.adInfo);

  return (
    <InstagramMockupWrapper>
        <AdMockupTop>
            <AdMockupLine></AdMockupLine>
            <AdMockupAccount>
                <AdMockupAccountInfo>
                    <img src={facebook_page?.picture} />
                    <div>
                        <h6>{facebook_page?.name}</h6>
                        <InfoDetails>   
                            <p>Sponsored </p> 
                            <DailyprofitSvg Icon='TriangleArrow' /> 
                            <p>Expand Story</p>
                        </InfoDetails> 
                    </div>
                </AdMockupAccountInfo>
                <AdMockupAccountActions>
                    <DailyprofitSvg Icon='InstaOptions' />
                    <IconSvg Icon='Xicon'/>
                </AdMockupAccountActions>
            </AdMockupAccount>
        </AdMockupTop>
            <AdMockupImageWrapper>
                <AdMockupImage>
                    <h4>{props.products[0]?.name}</h4>
             
                    {props.products?.length > 0 && (
                        <img 
                            src={getFirstImageUrl(props.products[0].image_url)}
                            alt="Product"
                        />
                    )}
                </AdMockupImage>
                <AdMockupText>
                    <h4>{props.products[0]?.name}</h4>
                    <h4>{props.adInfo?.caption && props.adInfo?.caption?.length > 10 ? props.adInfo?.caption?.substring(0, 10) + '...' : props.adInfo?.caption}</h4>
                    
                </AdMockupText>
            </AdMockupImageWrapper>


        <AdMockupBottomWrapper>
            <AdMockupBottom>
                {/* <h2>{facebook_page?.name}</h2> */}
                <IconSvg Icon='Back' />
                <AdMockButton>
                    <h5>{findLabelByValue(props.adInfo?.cta, t)}</h5>
                </AdMockButton>
            </AdMockupBottom>
        </AdMockupBottomWrapper>
    </InstagramMockupWrapper>
  )
}
