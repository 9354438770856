import React from 'react'
import styled from 'styled-components'
import { H2, P } from '../../../styles/components/HomeText'
import { Cta_, H5, IconSvg, device } from 'monica-alexandria'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { useTranslation } from 'react-i18next'

const HomeHelp = styled.section`
  
    h2{
        text-align: center;
        //color: var(--main);
    }
`

const HomeHelpWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding-bottom: 4rem;

    @media ${device.md}{
        grid-template-columns: 1fr 1fr ;
    }

`

const HomeHelpBox = styled.div`

padding: 4rem;
    border-radius: var(--normalRadius);
    box-shadow: ${p => p.theme.out};
    background: ${p => p.theme.mid};
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    align-content: start;
  

    @media ${device.lg}{
        grid-template-columns: 1fr  ;
    }

    svg{
        fill: ${p => p.theme.color};
        width: 2.5rem;

    }
  
`
const HomeHelpAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

`


export const Home_Help = () => {
    const {t} = useTranslation();
  return (
    <HomeHelp>
        <H2>QuestAd can help you</H2>
        <HomeHelpWrapper>
            <HomeHelpBox>
              <DailyprofitSvg Icon='Automatic' />
                <H5>{t("Automate your social media marketing in few clicks.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <IconSvg Icon='Rocket' />
                <H5>{t("Tailored campaigns to suit your business needs.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <DailyprofitSvg Icon='Roi' />
                <H5>{t("Data-driven strategies for maximum ROI.")}</H5>
            </HomeHelpBox>
            <HomeHelpBox>
                <IconSvg Icon='Xicon' />
                <H5>{t("No digital agency or marketeer needed.")}</H5>
            </HomeHelpBox>
           
        </HomeHelpWrapper>
        {/* <HomeHelpAction>
            <Cta_ text='Start Now' size='large'/>
        </HomeHelpAction>    */}
    </HomeHelp>
  )
}
