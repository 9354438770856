import { Card, Dark_Plain, Dropdown_, Grey_, PageAlwaysVisible, PageSixty, device } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import Dashboard_Sidebar from './Dashboard_Sidebar'
import Dashboard_Top from './Dashboard_Top'
import Dashboard_Stats from './Dashboard_Stats'
import Dashboard_Leads from './leads/Dashboard_Leads'
import Dashboard_Edit from './edit/Dashboard_Edit'
import Dashboard_Active from './Dashboard_Active'
import Dashboard_Archived from './archived/Dashboard_Archived'
import Dashboard_Draft_Quests from './Dashboard_Draft_Quests'

import { useParams } from 'react-router-dom'
import Dashboard_Subscription from './subscription/Dashboard_Subscription'
import { ViewContext } from '../contexts/ViewContext'
import { SidebarContext } from '../contexts/SidebarContext'
import Dashboard_No_Quests from './noQuests/Dashboard_No_Quests'
import Dashboard_History from './history/Dashboard_History'
import Dashboard_Reccomendations from './reccomendations/Dashboard_Reccomendations'

const Revealsidebar = keyframes`
  0%{
    width: 100%
  }

  100%{
    width: calc(100% - 20rem);
  }
`

const Hidesidebar = keyframes`
  0%{
    width: calc(100% - 20rem);
  }

  100%{
    width: 100%;
  }
`

const Slideoutsidebar = keyframes`
  0%{
    transform: translateX(0);
  }

  100%{
    transform: translateX(25rem);
  }
`

const Slideinsidebar = keyframes`
  0%{
    transform: translateX(25rem);
  }

  100%{
    transform: translateX(0);
  }
`

const DashboardCard = styled.div`
  width: 100%;
  min-height: calc(100vh - 19rem);
  position: relative;
  border-radius: var(--normalRadius);
`

const DashboardCardWrapper = styled.div``

const DashboardCardBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${p => p.theme.mid};
  z-index: 1;
  border-radius: var(--normalRadius);
  box-shadow: ${p => p.theme.out};
`

const DashboardCardFront = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${p => p.sidebar ? 'calc(100% - 20rem)' : '100%'};
  height: 100%;
  background: ${p => p.theme.background};
  z-index: 2;
  animation: ${p => p.sidebar ? Revealsidebar : Hidesidebar } .5s linear;
  border-radius: var(--normalRadius);
  box-shadow: ${p => p.theme.out};
  padding: 2rem 4rem;
  max-height: 100%;
  overflow: auto;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & {
    scrollbar-width: none; /* For Firefox */
  }

  /* Show scrollbar on hover */
  &:hover {
    overflow: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* For Firefox */
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  @media ${device.lg} {
    width: 100%;
    animation: ${p => p.sidebar ? Slideoutsidebar : Slideinsidebar } .5s linear;
    transform: ${p => p.sidebar ? 'translateX(25rem)' : 'translateX(0rem)'};
    padding: 2rem;
  }  

  @media ${device.md} {
    width: 100%;
    animation: ${p => p.sidebar ? Revealsidebar : Hidesidebar } .5s linear;
    width: ${p => p.sidebar ? 'calc(100% - 20rem)' : '100%'};
  }

  @media ${device.sm} {
    width: 100%;
    animation: ${p => p.sidebar ? Slideoutsidebar : Slideinsidebar } .5s linear;
    transform: ${p => p.sidebar ? 'translateX(25rem)' : 'translateX(0rem)'};
  }
`



export default function Dashboard_Card(props) {

  const {t} = useTranslation();
  const {questId} = useParams();

  const {view, setView} = useContext(ViewContext);
  const {sidebar, toggleSidebar} = useContext(SidebarContext);

  const set_View = (view) => {
    setView(view)
    if (window.innerWidth < 768) {
      toggleSidebar(false);
    }
  }

  useEffect(() => {
    if (questId) set_View('editSubscription')
  },[])

  return (
    <DashboardCard>
      <DashboardCardBack>
        <Dashboard_Sidebar 
          view={view}
          type={props.type}
          onReccomendations={() => set_View('reccomendations')}
          onViewStats={() => set_View('viewStats')}
          onViewLeads={() => set_View('viewLeads')}
          onViewHistory={() => set_View('viewHistory')}
          onEditQuest={() => set_View('editQuest')}
          onEditSubscription={() => set_View('editSubscription')}
          onQuestsActive={() => set_View('questsActive')}
          onQuestsArchived={() => set_View('questsArchived')}
          onDraftQuests={() => set_View('questsDraft')}
        />
      </DashboardCardBack>
      {view === 'reccomendations' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
            <DashboardCardWrapper>
              <Dashboard_Top 
                onTopViewStats={() => setView('viewStats')}
                section = {t('RECCOMENDATIONS')}
                sidebar={sidebar}
                onClick={()=>toggleSidebar(!sidebar)}
                activeQuests={props.activeQuests}
                onChangeActiveQuest={props.onChangeActiveQuest}
                avatar={props.avatar}
                page={props.page}
                type={props.type}
                industry={props.industry}
                selectedQuestId={props.selectedQuestId}
              />
              <Dashboard_Reccomendations 
                reccomendationsNotifications={props.reccomendationsNotifications}
                activeQuests={props.activeQuests}
                activeSetSelectedQuest={props.activeSetSelectedQuest}
                activeViewSelectedQuest={() => setView('viewStats')}
                activeEditSelectedSubscription={() => setView('editSubscription')}
              />
            </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      :view === 'editQuest' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
          <DashboardCardWrapper>
            <Dashboard_Top 
              onTopViewStats={() => setView('viewStats')}
              section = {t('EDIT QUEST')}
              sidebar={sidebar}
              onClick={()=>toggleSidebar(!sidebar)}
              activeQuests={props.activeQuests}
              onChangeActiveQuest={props.onChangeActiveQuest}
              avatar={props.avatar}
              page={props.page}
              type={props.type}
              industry={props.industry}
              selectedQuestId={props.selectedQuestId}
            />
            <Dashboard_Edit 
              type={props.type}
              onEditPauseAds={props.onEditPauseAds}
              adAvatar={props.avatar}
              adPage={props.page}
              adCaption={props.adCaption}
              adImg={props.adImg}
              adTitle={props.adTitle}
              adUrl={props.adUrl}
              adCta={props.adCta}
              onAdView={props.onAdView}
              onAdEdit={props.onAdEdit}
              landingCustom={props.landingCustom}
              landingImg={props.landingImg}
              landingHero={props.landingHero}
              landingSub={props.landingSub}
              landingFields={props.landingFields}
              onLandingView={props.onLandingView}
              hasLandingEdit={props.hasLandingEdit}
              onLandingEdit={props.onLandingEdit}
              questLocations={props.questLocations}
              questInterests={props.questInterests}
              questMinAge={props.questMinAge}
              questMaxAge={props.questMaxAge}
              questGender={props.questGender}
              onQuestEdit={props.onQuestEdit}
              page={props.page}
              pagePicture={props.pagePicture}
            />
          </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      :view === 'editSubscription' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
          <DashboardCardWrapper>
            <Dashboard_Top 
              onTopViewStats={() => setView('viewStats')}
              section = {t('EDIT SUBSCRIPTION')}
              sidebar={sidebar}
              onClick={()=>toggleSidebar(!sidebar)}
              activeQuests={props.activeQuests}
              onChangeActiveQuest={props.onChangeActiveQuest}
              avatar={props.avatar}
              page={props.page}
              type={props.type}
              industry={props.industry}
              selectedQuestId={props.selectedQuestId}
            />
            <Dashboard_Subscription 
              amountPaid={props.amountPaid}
              amountTopUp={props.amountTopUp}
              subscriptionStatus={props.subscriptionStatus}
              subscriptionCancelOnPeriodEnd={props.subscriptionCancelOnPeriodEnd}
              subscriptionNextPayment={props.subscriptionNextPayment}
              onSubscriptionTopup={props.onSubscriptionTopup}
              onSubscriptionDetails={props.onSubscriptionDetails}
              onSubscriptionCancel={props.onSubscriptionCancel}
              onSubscriptionRenew={props?.onSubscriptionRenew}
            />
          </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      :view === 'viewHistory' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
            <DashboardCardWrapper>
              <Dashboard_Top 
                onTopViewStats={() => setView('viewStats')}
                section = {t('ACTIONS HISTORY')}
                sidebar={sidebar}
                onClick={()=>toggleSidebar(!sidebar)}
                activeQuests={props.activeQuests}
                onChangeActiveQuest={props.onChangeActiveQuest}
                avatar={props.avatar}
                page={props.page}
                type={props.type}
                industry={props.industry}
                selectedQuestId={props.selectedQuestId}
              />
              <Dashboard_History 
                historyNotifications={props.historyNotifications}
              />
            </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
      </DashboardCardFront>
      :view === 'viewLeads' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
            <DashboardCardWrapper>
              <Dashboard_Top 
                onTopViewStats={() => setView('viewStats')}
                section = {t('PROSPECT LEADS')}
                sidebar={sidebar}
                onClick={()=>toggleSidebar(!sidebar)}
                activeQuests={props.activeQuests}
                onChangeActiveQuest={props.onChangeActiveQuest}
                selectedQuestId={props.selectedQuestId}
                avatar={props.avatar}
                page={props.page}
                type={props.type}
                industry={props.industry}
              />
              <Dashboard_Leads
                selectedQuestId={props.selectedQuestId}
                leadsTotal={props.leadsTotal}
              />
            </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      :view === 'questsActive' ?
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
            <DashboardCardWrapper>
              <Dashboard_Top 
                onTopViewStats={() => setView('viewStats')}
                general
                section = {t('ACTIVE QUESTS')}
                sidebar={sidebar}
                onClick={()=>toggleSidebar(!sidebar)}
              />
              <Dashboard_Active 
                activeQuests={props.activeQuests}
                activeSetSelectedQuest={props.activeSetSelectedQuest}
                activeViewSelectedQuest={() => setView('viewStats')}
                activeEditSelectedSubscription={() => setView('editSubscription')}
              />
            </DashboardCardWrapper>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      :view === 'questsArchived' ?
        <DashboardCardFront sidebar={sidebar}>
          <Dashboard_Top 
            onTopViewStats={() => setView('viewStats')}
            general
            section = {t('ARCHIVED QUESTS')}
            sidebar={sidebar}
            onClick={()=>toggleSidebar(!sidebar)}
          />
          <Dashboard_Archived
            archivedQuests={props.archivedQuests}
            archivedSetSelectedQuest={props.archivedSetSelectedQuest}
            archivedOpenBudget={props.archivedOpenBudget}
            // type={props.type}
            archivedOpenLeads={props.archivedOpenLeads}
          />
        </DashboardCardFront>
      // :view === 'questsDraft' ?
      //   <DashboardCardFront sidebar={sidebar}>
      //     <Dashboard_Top 
      //       onTopViewStats={() => setView('viewStats')}
      //       general
      //       section = {t('DRAFT QUEST')}
      //       sidebar={sidebar}
      //       onClick={()=>toggleSidebar(!sidebar)}
      //     />
      //     <Dashboard_Draft_Quests
      //       draftQuests={props.draftQuests}
      //     />
      //   </DashboardCardFront>
      :
        <DashboardCardFront sidebar={sidebar}>
          {props.activeQuests?.length ?
          <>
            <Dashboard_Top 
              stats
              onTopViewStats={() => setView('viewStats')}
              section = {t('OVERVIEW')}
              sidebar={sidebar}
              onClick={()=>toggleSidebar(!sidebar)}
              activeQuests={props.activeQuests}
              selectedQuestId={props.selectedQuestId}
              onChangeActiveQuest={props.onChangeActiveQuest}
              avatar={props.avatar}
              page={props.page}
              type={props.type}
              industry={props.industry}
            />
            <Dashboard_Stats 
              status={props.status}
              interval={props.interval}
              onInterval={props.onInterval}
              onViewLeads={() => setView('viewLeads')}
              onEditQuest={() => setView('editQuest')}
              onEditSubscription={() => setView('editSubscription')}
              type={props.type}
              days={props.days} 
              totalBudget = {props.totalBudget}
              spentBudget = {props.spentBudget}
              pageviews={props.pageviews}
              engagement={props.engagement}
              leads={props.leads}
              leadsOverview={props.leadsOverview}
              onLeads={() => setView('viewLeads')}
              onEngagement={() => setView('engagement')}
              onPageviews={() => setView('pageviews')}
              graphTotal = {props.graphTotal}
              graphTitle = {props.graphTitle}
              graphData = {props.graphData}
              kpis = {props.kpis}
              onKpi = {props.onKpi}
              kpiSelected = {props.kpiSelected}
            />
          </>
          :
            <Dashboard_No_Quests onNoQuestsAdd={props.onNewQuest} />
          }
        </DashboardCardFront>
      }
    </DashboardCard>
  )
}
