import { IconSvg, device } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'


const InstagramMockupWrapper = styled.div`
    max-width: 40rem;
    width: 100%;
    width: 100vw;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    background: var(--white);


    @media ${device.sm}{
        width: 35rem;
    }

`

const AdMockupTitle = styled.div`
    h5{
        color: var(--black);
    }
`
const AdMockButton = styled.div`

    svg{
        width: 1.8rem;
        fill: var(--black);
    }
`

const AdMockupSection = styled.div`
    background: var(--white);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const AdMockupAccount = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: var(--normalPads);
    svg{
        width: 2rem;
        height: 2rem;

        fill: var(--black);
        stroke: none;
    }
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    gap: 1rem;

    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
    }

    h5,p{
        color: var(--black);
    }
`

const AdMockupImage = styled.div`
    height : 40rem;

    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid var(--grey);

    svg{
        stroke: var(--black);
        stroke-width: 2;
        fill: none;
        width: 2.8rem;
        height: 2.8rem;
    }

    .instaicon {
        width: 2.4rem;
        height: 2.4rem;
    }
`

const AdMockupActionsLeft = styled.div`
    display: flex;
    align-items: center;
    gap:1rem;
`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Instagram_Mockup_Feed = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const {questId} = useParams();


  return (
    <InstagramMockupWrapper>
        <AdMockupAccount>
            <AdMockupAccountInfo>
                <img src={questId ? props?.adInfo?.facebookPagePicture :facebook_page?.picture} />
                <div>
                    <h4>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h4>
                    <p>Sponsored</p>
                </div>
            </AdMockupAccountInfo>
            <DailyprofitSvg Icon='InstaOptions' />
        </AdMockupAccount>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupSection>
            <AdMockupTitle>
                <h5>{findLabelByValue(props.adInfo?.cta, t)}</h5>
            </AdMockupTitle>
            <AdMockButton>
                <IconSvg Icon='Next' />
            </AdMockButton>
        </AdMockupSection>
        <AdMockupActions>
            <AdMockupActionsLeft>
                <DailyprofitSvg Icon='InstaHeart'  />
                <DailyprofitSvg Icon='InstaComment' class_Icon='instaicon' />
                <DailyprofitSvg Icon='InstaShare' class_Icon='instaicon' />
            </AdMockupActionsLeft>
            <DailyprofitSvg Icon='InstaSave' class_Icon='instaicon' />
        </AdMockupActions>
    </InstagramMockupWrapper>
  )
}
