import { Actions_, Alert, AlertContext, Alert_, Builder_, Dropdown_, Grey_, Grey_Link, Input_, Main_, Main_Plain, Page, PageAlwaysVisible, Section_Title, PageDesktopOnly, PageForty, PageTitle, Upload_Media, device, Main_Loader, Partial_Loader, Partial_Text_Loader, Main_Text_Loader, Confirmation_, Form, Slider, IconSvg, Textarea_, PageDesktopPadding, Toggle_Stat, ValidatorError } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/messagesAlerts';
import a from '../../services/api/Ads'
import m from '../../services/api/Meta'
import q from '../../services/api/Quests'
import styled from 'styled-components';
import { load_my_ad, prepare_ad_for_saving_in_DB } from './utils';
import { Preview_Ad_Mockup } from '../../components/ad/Preview_Ad_Mockup';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { Ad_Templates } from '../../components/ad/Ad_Templates';
import { Helmet } from 'react-helmet';
import Preview_Catalog_Mockup from '../../components/ad/Preview_Catalog_Mockup';
import { ModifyAdToggle } from '../../styles/components/ModifyAd';
import { Create_Ad_Service_Only_Fields } from '../../components/ad/Create_Ad_Service_Only_Fields';
import { Create_Ad_Fields } from '../../components/ad/Create_Ad_Fields';

const CreateAd = styled.div`
    .unclickable{
        opacity: 0.7;
        pointer-events: none;
    }
    .UploadMediaGrid{
        background: ${p => p.theme.background};
        padding: 1rem;
        border-radius: 1rem;
    }
`

const AdMockupWrapper = styled.div`
    padding-top: 6rem;
    display: flex;
    align-items: center;

    @media ${device.md} {
       padding-top: 0rem;
    }
`

const CatalogMockupWrapper = styled.div`



`


export default function CREATE_AD() {

    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);

    const {lang} = useParams();
    const navigate = useNavigate();

    const [adInfo, setAdInfo] = useState({caption:"", title: "",cta:"", media:""})
    const [loading, setLoading] = useState(false);

    // eshop flow
    const [businessType, setBusinessType] = useState(false);
    
    const [productCatalogs, setProductCatalogs] = useState([]);
    const [productSets, setProductSets] = useState([]);
    const [products, setProducts] = useState([])

    const [confirmation, setConfirmation] = useState(false);
    const [adsLoading, setAdsLoading] = useState(true);

    const [showTemplates, setShowTemplates] = useState({id:'', slider:''});

    const [emptyFields, setEmptyFields] = useState([]);
    const [mediaErrors, setMediaErrors] = useState([]);
    const [ads, setAds] = useState([]);

    const [leadNotif, toggleLeadNotif] = useState(false);

    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');
 
    // Searches for previous quests in order to reuse an ad, if possible
    // const check_for_previous_quests = () =>{
    //     q.getMyQuests()
    //     .then(res => {
    //         if (res?.data?.code == 'Q5006') {

    //             let quests = res?.data?.data;

    //             if (quests?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
    //             setConfirmation(true);
    //             }
    //         }
    //     })
    // }


    
    const get_my_ad = async(questId) => {
        try {
            await a.getMyAd(questId)
                .then(res => {
                    if (res?.data?.code == "A2002") {
                        setAdInfo((prev) => ({
                            ...prev,
                            ...load_my_ad(res?.data?.ad),
                        }));
                        
                        if (res?.data?.ad?.flow === 'eshop' && res?.data?.ad?.mode==="productCatalog"){
                            get_product_catalogs();
                            get_product_sets(res?.data?.ad?.catalogId, false)
                            // get_products_from_product_set(res?.data?.ad?.productSetId ,'')
                        } 
                    }
            })
        } catch (err) {
            console.log(err);
        }
    }





    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_no = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
    }

    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_yes = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
        setShowTemplates({...showTemplates, slider:true})
        // navigate(`/${lang}/choose-ad`);
    }

    const check_for_ad = async() => {
        await a.checkForAd()
        .then(res => {
            console.log('CHECK FOR AD', res?.data)
            setBusinessType(res?.data?.field);
            if (res?.data?.ad_exists) get_my_ad(res?.data?.questId)
            else setAdInfo({...adInfo, questId: res?.data?.questId, mode: res?.data?.mode, flow: res?.data?.flow || '', catalogId: res?.data?.catalogId || ''});
        })
        .catch(err => console.log(err))
    }



    const get_my_ads = async() => {
        try {
            await a.getMyAds()
            .then(res => {
                if (res?.data?.code == 'A2006') {
                    console.log('RESADS',res?.data?.ads );
                    handleFilter(adInfo.flow,adInfo?.mode, res?.data?.ads )
                    //setAds(res?.data?.ads);
                }
                else {
                    alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
                }
                setAdsLoading(false);
            })
        } catch (err) {
            console.log(err);
        }
    }

    //template ads 
    const handleFilter = (flow, mode, ads) => {
        const filteredData = ads.filter((item) => {

        if (flow === 'eshop' && mode) {
            return item.flow === flow && item.mode === mode;
        } else if (flow) {
            return item.flow === flow;
        } else {
            return item; // No filter applied, return everything
        }
        })
        
        // if (filteredData?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
        //     setConfirmation(true);
        // }

         setAds(filteredData);
    };




    const validateFields = () => {
        const emptyFields = [];
     
        if (!adInfo.caption) {
            emptyFields.push('Caption');
        }
        if ((!adInfo.title || adInfo?.title === "") && adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Headline');
        }
        if (adInfo.cta === 'none' || !adInfo.cta) { // Check if cta is 'none' or empty
            emptyFields.push('Cta');
        }   
        if (!adInfo.media && !adInfo?.visualElement &&  adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Media');
            setMediaErrors([])
        }
        if (businessType == 'eshop' && adInfo?.mode === 'productCatalog' && (!adInfo?.catalogId ||adInfo?.catalogId ==='') ) {
            emptyFields.push('Catalog');
        }
        if (businessType == 'eshop' && adInfo?.mode === 'productCatalog' && productSets?.length  && !adInfo?.productSetId)  {
            emptyFields.push('ProductSet');
        }
       setEmptyFields(emptyFields)
        return emptyFields;
    };

    //onSave
    const edit_ad = (isDraft) => {
    
        let adFinal = prepare_ad_for_saving_in_DB(adInfo, isDraft);
        console.error('adFinal',adFinal);
        setLoading(t('Uploading media and creating your ad. This may take a moment...'));
        a.editAd(adFinal, adInfo?.file, 'draft')
         .then(res => {
             setLoading(false);
             // eshop flow
             if (businessType == 'eshop' || businessType == 'products offline' || businessType == 'restaurants') navigate(`/${lang}/preview-ad`)
             else if (res?.data?.code == 'A2001') navigate(`/${lang}/webpage-helper`)
             else alertMessage(alerts, setAlerts, res?.data?.msg,'Error');
        })
    }


      const edit_quest_mode = (newMode) =>{
        const newMetaField = {
            'Meta.mode': newMode,   
            questId: adInfo?.questId
        };


        q.editQuestMode( newMetaField )
        .then(res => {
            // check_for_ad();
            setAdInfo({...adInfo, mode: newMode});
           if (newMode==='productCatalog') get_product_catalogs(); 
        })
      }


    const get_product_catalogs = async() => {
        try {
            await m.getProductCatalogs()
            .then(res => {
                console.log('adsfgd', res)
                setProductCatalogs(res?.data?.productCatalogs)
            // get_product_sets()
                
            })
        } catch (err) {
            console.log(err);
        }
    }

    //called on change dropdown
    const save_product_catalog = async(catalogId) =>{
        setAdInfo((prev) => ({
            ...prev,
            catalogId: catalogId,
          }));
        const selectedCatalog = productCatalogs.find(catalog => catalogId === catalog?.catalogId);
        console.log('catalog--',selectedCatalog);
      
        get_product_sets(catalogId, true)
        
        try {
            await m.saveCatalog(selectedCatalog?.catalogId, selectedCatalog?.catalogName, selectedCatalog?.catalogBusinessId, adInfo?.questId);
        } catch (err) {
            console.log(err);
        }
      }



      const get_product_sets = async (catalogId, changed) => {

        try {
            await m.getProductSets(catalogId)
            .then(res => {
                console.log('adsfgd++++++++', res?.data?.productSets[0])
                setProductSets(res?.data?.productSets)

                if(changed) {
                    setAdInfo(prev => ({
                        ...prev,
                        productSetId: res?.data?.productSets[0]?.id || '',
                        productSetName: res?.data?.productSets[0]?.name || '',
                    }));
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    const get_products_from_product_set = async(productSetId, productSetName) => {
        try {
            await q.getProductsFromProductSet(adInfo?.questId, productSetId)
            .then(res => {
                console.log('RESPRODSET', res?.data);
                
                if (!["Q2010", "Q2011"]?.includes(res?.data?.code)) {
                    setProducts([]);
                    console.log('res?.data?.msg', res?.data?.msg);
                    
                    alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
                }
                else {
                    setProducts(res?.data?.products);
                    edit_product_set(productSetId, productSetName,res?.data?.products[0]?.image_url  )
                
                }
            })
        } catch (error) {
            console.error('Error fetching product sets:', error);
            // Handle the error, e.g., set an error state or show an alert
        }
    }

    const edit_product_set = async(productSetId, productSetName, productSetImg) =>{
        const newMetaField = {
            'Meta.productSetId': productSetId,
            'Meta.productSetName': productSetName,
            'Meta.productSetImg': productSetImg,

            questId: adInfo?.questId
        };
        
    
        setAdInfo(prev => ({
            ...prev,
            productSetId: productSetId,
            productSetName: productSetName,
        }));

        
        try {
            await q.editQuestMode(newMetaField);
        } catch (err) {
            console.log(err);
        }
    }



    const haveProductsLoaded = () => {
        return products?.length && products.some(product => product?.name && product?.name !== '');
    };
    
    
    
    console.log('productCatelgs', productCatalogs);
    console.log('productSets', productSets);
    console.error('adinfo', adInfo);
    

    useEffect(() => {
        check_for_ad();
        // check_for_previous_quests();
      //  get_my_ads();
    }, [])

    useEffect(()=>{
        get_my_ads();
    }, [adInfo?.flow, adInfo?.mode])


    useEffect(() => {
       if (adInfo?.productSetId) get_products_from_product_set(adInfo?.productSetId, adInfo?.productSetName)
    }, [adInfo?.productSetId])

    console.log('adInfo----', adInfo);
    console.log('products----', products);
    
    

    
  if (loading) return <Main_Text_Loader text={loading}/>
  return (
    <CreateAd>
        <Helmet>
            <title>Questad | Create advertisement</title>
        </Helmet>
        {showTemplates?.slider && 
            <Ad_Templates adInfo={adInfo} ads={ads} setAds={setAds} adsLoading={adsLoading} setShowTemplates={setShowTemplates}/>
        }
    <PageForty>
        {confirmation  ? <Confirmation_
                message={t("Would you like to reuse an already existing ad?")}
                no={t("No")}
                onNo={() => choice_no()}
                onYes={()=> choice_yes()}
                yes={t("Yes")}
          
          /> : <></>}
        <Alert_
            messages={alerts}
            duration = {10000}
        />
     
        <PageAlwaysVisible>
                <PageTitle cta>
                    <h1>{t('Create ad')}</h1>
                    {/* {store?.getState()?.reuseAd == 'DECIDED' ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})} /> : <></>} */}
                    {ads?.length ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})}  /> : null}
                </PageTitle>
                {adInfo?.flow == 'eshop' ? 
                    <Builder_ >
                        <ModifyAdToggle>
                            <Toggle_Stat
                                text={t('I have a product catalog')}
                                active={adInfo?.mode == 'default' ? false: true} 
                                onClick={()=> {
                                    //setProductCatalog(!productCatalog)
                                    if (adInfo?.mode =='productCatalog') edit_quest_mode('default')
                                    else edit_quest_mode('productCatalog')
                                }} 
                                
                            /> 
                        </ModifyAdToggle>
                    </Builder_>
                : 
                    null
                }

                {isKnight ? 
                    <Builder_ >
                        <ModifyAdToggle>
                            <Toggle_Stat
                                text={t('Real-time Lead Notifications')}
                                onClick={() => toggleLeadNotif(!leadNotif)}
                                active={leadNotif} 
                            /> 
                        </ModifyAdToggle>
                    </Builder_>
                : 
                    null
                }
            <Form>
                <Create_Ad_Fields
                    adInfo={adInfo} 
                    setAdInfo={setAdInfo} 
                    emptyFields={emptyFields} 
                    setEmptyFields={setEmptyFields}
                />

                {adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog' ?  null :
                    <Create_Ad_Service_Only_Fields
                        adInfo={adInfo} 
                        setAdInfo={setAdInfo} 
                        emptyFields={emptyFields} 
                        setEmptyFields={setEmptyFields}
                        mediaErrors={mediaErrors}
                        setMediaErrors={setMediaErrors}
                    />
                    }
                    



                {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog'  ? 
                    <div>
                        <Builder_ title={t('Select a product catalog')}>
                            <Dropdown_>
                                <select 
                                    value ={adInfo?.catalogId} 
                                    onChange={(e) => save_product_catalog(e.target.value)}
                                >
                                    <option value={''} disabled>{t('Product catalog')}</option>
                                    {productCatalogs?.map((catalog) => (
                                        <option
                                            key={catalog?.catalogId}
                                            value={catalog?.catalogId}
                                        >
                                            {catalog?.catalogName}
                                        </option>
                                    ))}
                                </select>
                            </Dropdown_>

                            {emptyFields?.includes('Catalog') ? (
                                    <ValidatorError>
                                        <IconSvg Icon="Error" />
                                        <h6>{t('Product catalog must be selected')}</h6>
                                    </ValidatorError>
                           ) : null} 
                        </Builder_>


                        <Builder_ title={t('Select a product set')}>
                                <Dropdown_>
                                    <select 
                                        value ={adInfo?.productSetId || ''} 
                                        onChange={(e) => edit_product_set(e.target.value, e.target.selectedOptions[0].text)}
                                    >
                                        <option value={''} disabled>{t('Product set')}</option>
                                        {productSets?.map((product) => (
                                            <option
                                                key={product.id}
                                                value={product.id}
                                            >
                                                {product.name}
                                            </option>
                                        ))}
                                    </select>
                                </Dropdown_>
                            {emptyFields?.includes('ProductSet') ? (
                                <ValidatorError>
                                    <IconSvg Icon="Error" />
                                    <h6>{t('Product set must be selected')}</h6>
                                </ValidatorError>
                             ) : null} 
                            </Builder_>





                    </div>
                : null
                }
            </Form>




           <Actions_>
                <Grey_ iconLeft="Back" text={t('Back')} onClick={() => navigate(`/${lang}/questionnaire/beta/`)} noPadding />
                {/* {store?.getState()?.reuseAd == 'DECIDED' ? <Main_Plain text={t('Reuse Ad')} iconLeft='EyeOpen' onClick={()=> navigate(`/${lang}/choose-ad`)} /> : <></>} */}
   
                <Main_ 
                    text={t('Done')} 
                    className = {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog' && !products?.length ? 'unclickable' :''}
                    iconLeft='EyeOpen'
                        onClick={() => {
                            if (validateFields()?.length ) {
                                alertMessage(alerts, setAlerts, (t('Please check fields')),'Error')
                                return; }
                            else edit_ad(false)
                        }}  
                />
           </Actions_>
        </PageAlwaysVisible>
        <PageDesktopOnly>
            { adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog'  ?
                <AdMockupWrapper>
                    <Preview_Catalog_Mockup products={products} edit_product_set={''} adInfo={adInfo} setAdInfo={setAdInfo}/>
                </AdMockupWrapper>
            :
                <AdMockupWrapper>
                    <Preview_Ad_Mockup adInfo={adInfo} />
                </AdMockupWrapper>
            }
        </PageDesktopOnly>
    </PageForty>
    </CreateAd>
  )
}



