import { Card, Grey_Link, IconSvg, LimitCharacters, Main_Plain } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DashboardQuest = styled(Card)``

const DashboardQuestMain = styled.div`
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: ${p => p.theme.background};

  &:hover{
      .View{
        svg{
          transform: translateX(1rem);
        }
      }
  }
`

const DashboardQuestRight = styled.div`
  
  svg{
    width: 2rem;
    fill: ${p => p.theme.color};
    transition: all .5s;
  }
`

const DashboardQuestLeft = styled.div`
  
`

const DashboardQuestTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  

  img{
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid ${p => p.theme.color};
    background: ${p => p.theme.color};
  }
`

const DashboardQuestActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: ${p => p.theme.background};
    margin-top: 2px;
`

const DashboardQuestTitle = styled.div`
  
  h5{
    white-space: nowrap;
  }
`

const DashboardQuestInfo = styled.div`
  h6{
    color: ${p => p.cancelled ? 'var(--warning)' : 'var(--greyDark)'};
  }
`

const DashboardQuestBudget = styled.div`
  margin-top: 1rem;

  h1{
    color: var(--focus);
  }
`

// avatar='https://scontent.fath2-1.fna.fbcdn.net/v/t39.30808-1/215360302_342330450770357_5777270674996519270_n.png?stp=cp0_dst-png_p50x50&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=90N3FSMFKLYQ7kNvgE9gI2Y&_nc_ht=scontent.fath2-1.fna&edm=AGaHXAAEAAAA&oh=00_AYDjRB04VtKMAAmyc8shlexs9N4zSAag6WyOq4QXzv_OGA&oe=6648F4DD'
// page={quest?.Meta?.facebookPageName}
// type={quest?.questionnaires[0]?.answer}
// industry={quest?.questionnaires[1]?.answer}
// totalBudget={quest?.questionnaires[5]?.answer}

export default function Dashboard_Quest(props) {

  const {t} = useTranslation();

  return (
    <DashboardQuest key={props.key}>
      <DashboardQuestMain onClick={props.onClick}>
        <DashboardQuestLeft>
          <DashboardQuestTop>
            <img src={props.avatar} />
            <DashboardQuestTitle>
              <h5>{props.type} | {LimitCharacters(props.industry, 12)}</h5>
              <p>{LimitCharacters(props.page, 25)}</p>
            </DashboardQuestTitle>
          </DashboardQuestTop>
          <DashboardQuestBudget>
            <h1>€ {props.totalBudget},00</h1>
          </DashboardQuestBudget>
          <DashboardQuestInfo cancelled={props.cancelled}>
            <h6>{t("Next payment")}:</h6>
          {props.cancelled ?
            <h6>{t("Auto renewal has been cancelled")}</h6>
          :
            <h6>{props.renewalDate}</h6>
          }
          </DashboardQuestInfo>
        </DashboardQuestLeft>
        <DashboardQuestRight className='View'>
          {/* <IconSvg Icon="Next" /> */}
        </DashboardQuestRight>
      </DashboardQuestMain>
      {/* <DashboardQuestActions>
        {props.cancelled ?
          <Main_Plain size="small" noPadding iconLeft="Recycle" text={t("Reactivate subscription")} onClick={props.onActiveReactivate}/>
        :
          <Main_Plain size="small" noPadding iconLeft="Payment" text={t("Manage subscription")} onClick={props.onActiveSubscription}/>
        }
      </DashboardQuestActions> */}
    </DashboardQuest>
  )
}
