import React from 'react'
import styled from 'styled-components'
import { IconSvg, device } from 'monica-alexandria'
import ReactPlayer from 'react-player'
import store from '../../../redux/store'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import { useTranslation } from 'react-i18next'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'

const PreviewAdMockup = styled.div`
    width: 100%;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    background: #fff;
    max-width: 50rem;
    p{
        font-size: 1.4rem;
    }
    h1,h2,h3,h4,h5,h6,p{
        color: var(--black);
    }

`

const AdMockupTitle = styled.div`
  h3{
    font-size: 1.8rem  ;
    padding: 0.3rem 0;
    color: #000;
  }
`
const AdMockButton = styled.div`
    background-color: #E4E6EB;
    padding:0.7rem 1rem ;
    border-radius: 0.7rem;

    h5{
        color: black;
    }
`

const AdMockupCaption = styled.div`
    padding: var(--normalPads);
`

const AdMockupSection = styled.div`
    background:#F0F2F5;
    padding: var(--normalPads);
  
    display: flex;
    align-items: center;
    justify-content: space-between;
`


const AdMockupTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--normalPads);

`
const AdMockupPageText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`
const AdMockupPage = styled.div`
   
    display: flex;
    gap: 1rem;

    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
    }
`
const AdMockupTopActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    svg{
        width: 2rem;
        fill: #606770;
    }
`

const AdMockupImage = styled.div`
    
    img{
        max-height: 50rem;
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-top: 1px solid var(--grey);

    svg{
        width: 3.2rem;
        stroke: #606770;
        stroke-width: 1.2;
        fill: none;
    }
    h5{
        color: #606770;
    }
   // border-bottom: 1px solid var(--greyDark);
`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const dailyprofitUrl = process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST + 
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT;

const ogTitle = process.env.REACT_APP_OG_TITLE;


export const Desktop_Feed_Standard = (props) => {

    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;
    const {t} = useTranslation();

    const {questId} = useParams();
  
  return (
    <PreviewAdMockup>
        <AdMockupTop>
            <AdMockupPage>
                <img src={questId ? props?.adInfo?.facebookPagePicture :facebook_page?.picture} />
                <AdMockupPageText>
                    <h4>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h4>
                    <p>Sponsored</p>
                </AdMockupPageText>
            </AdMockupPage>
            <AdMockupTopActions>
                <DailyprofitSvg Icon='InstaOptions' />
                <IconSvg Icon='Xicon' />
            </AdMockupTopActions>
        </AdMockupTop>
        <AdMockupCaption>
            <h5>{props?.adInfo?.caption}</h5>
        </AdMockupCaption>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                    //config={facebookConfig}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupSection>
            <AdMockupTitle>
                <p>{dailyprofitUrl}</p>
                <h3>{props.adInfo?.title}</h3>
                <p>{ogTitle}</p>
            </AdMockupTitle>
            <AdMockButton>
                <h5>{findLabelByValue(props.adInfo?.cta,t)}</h5>
            </AdMockButton>
        </AdMockupSection>
     
        <AdMockupActions>
            <DailyprofitSvg Icon='FbShare' />
            <h5>Share</h5>
        </AdMockupActions>
    </PreviewAdMockup>
  )
}
