import { Button } from 'monica-alexandria'
import styled, { keyframes } from 'styled-components'


export const AdMockupTitle = styled.div`

`
export const AdMockButton = styled(Button)`
    background-color: ${p => p.theme.mid};
    padding: 1rem ;
    border-radius: 1rem;

    span{
        color: ${p => p.theme.color};
    }
`

export const AdMockupCaption = styled.div`
    padding: var(--normalPads);
`

export const AdMockupSection = styled.div`
    background-color: ${(props) => props.theme.background};
    padding: var(--normalPads);
  
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const AdMockupPage = styled.div`
    padding: var(--normalPads);
    display: flex;
    gap: 1rem;

    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 5rem;
    }
`

export const AdMockupImage = styled.div`

    img{
        max-height : 50rem;
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

export const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 0rem;
    border-top: 1px solid var(--greyDark);
    border-bottom: 1px solid var(--greyDark);
`

export const AdMockupCarouselItem = styled.div`
    position: relative;
    background: ${p => p.theme.low};
    margin: 1rem;
    border-radius: 1rem;
`

export const AdMockupCarouselImg = styled.img``

export const AdMockupCarouselText = styled.div`
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
`

