import { Footer_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Home_Footer = () => {
    const {t} = useTranslation();
  return (
    <Footer_
        sales={t('Sales Department')}
        development={t('Development Department')}
        marketing={t('Marketing Department')}
        follow={t('Follow us')}
        headquarters={t('Headquarters')}
        address={t('Mikrasiaton 75, Volos, Greece')}
        terms={t('Terms and Conditions')}
        privacy={t('Privacy Policy')}
        onTerms={() => window.location.href = 'https://launchpad.doitforme.eu/en/terms-and-conditions'}
        onPrivacy={() => window.location.href = 'https://launchpad.doitforme.eu/en/data-protection-policy'}
    />
  )
}
