import { AlertContext, CardsFull, Full_Card_Loader, Google_Search, Grey_Link, Main_Loader, Main_Plain, Main_Text_Loader, On_Click_Card, PageAlwaysVisible, PageFull, PageSubtitle, PageTitle, Partial_Loader, Partial_Text_Loader} from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import m from '../../services/api/Meta'
import a from '../../services/api/Ads'
import store from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../utils/messagesAlerts';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'
import Facebook_Page_Box from './components/Facebook_Page_Box'

const SearchWrapper = styled.div`
    padding-bottom: 4rem;
`

const FacebookPageSubtitle = styled(PageSubtitle)`
    p{
        display: flex;
        align-items: baseline;
        gap: .5rem;
    }
`

export default function FACEBOOK_PAGE() {

    const {t} = useTranslation();
    const access_token = store.getState().metaSource?.facebook_user?.accessToken;
    const navigate = useNavigate();
    const {lang,questId} = useParams();
    const {alerts, setAlerts} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);

    // fbPages with pagination
    const [next, setNext] = useState({
        cursor: 'initial',
        fbPages: []
    });

    const get_connected_facebook_pages = () => {
        m.getConnectedFacebookPages(access_token, next?.cursor)
            .then(res => {

                console.error(res)
                let oldPages = JSON.parse(JSON.stringify(next?.fbPages));
                

                const pages = res?.data?.data || [];
                const oldAndNewPages = oldPages.concat(pages);

                const cursor = res?.data?.next;
                if (!cursor) setLoading(false);

                else if (!oldAndNewPages?.length && res?.data?.code !== 'C5190') {
                    navigate(`/${lang}/facebook-pages-not-found/`);
                }
                else if (!oldAndNewPages?.length === 1) saveInfoAndNavigate(oldAndNewPages[0]);
                console.log('oldAndNewPages',oldAndNewPages);
                setNext({...next, fbPages: oldAndNewPages, cursor: cursor});
            })
            .catch(error => {
                console.error('Error fetching Facebook pages:', error);
                setLoading(false);
            });
    }
    
    const saveInfoAndNavigate = (page) => {

        
        store.dispatch({
            type: 'SAVE_PAGE_SELECTION',
             payload: {
                id: page.id,
                picture: page.picture,
                name: page.name,
                access_token: page["access_token"],
                draft: questId ? questId : 'draft'
             },
        });
        m.getConnectedInstagramAccount(page.access_token, page.id)
          .then(res => {
            store.dispatch({
                type: 'SAVE_INSTAGRAM_ACCOUNT',
                    payload: res?.data?.data || false,
                    draft: questId ? questId : 'draft'
            });
            a.saveFacebookPage({facebookPageId: page?.id, 
                                facebookPageName: page?.name, 
                                facebookPagePicture: page?.picture,
                                instagramAccountId: res?.data?.data?.id || false,
                                instagramAccountUsername: res?.data?.data?.username || false}, questId)
              .then((resB) => {
                  console.error('TESTTTTT', resB)
              })
            alertMessage(alerts, setAlerts,(t('Selected page '))+'"'+page.name+'"'+(res?.data?.data?.id ? (t(' and connected Instagram Account ')) +'"'+res?.data?.data?.username+'"' : ''), 'Success')
          })
        if (questId)   navigate(`/${lang}/dashboard`)
        else navigate(`/${lang}/questionnaire/beta/`)
    };

    useEffect(()=>{
        if (next?.cursor) get_connected_facebook_pages();
    }, [next?.cursor])

    console.log('NEXT', next)

   // if (loading) return <Partial_Loader  />
    return (
    <PageFull>
        <Helmet>
        <title> Questad | Select facebook page</title>
      </Helmet>
        {loading ? <Partial_Loader/> : null}
        <PageAlwaysVisible>
            <PageTitle>
                <Grey_Link iconLeft='Back' noPadding onClick={()=> questId ?navigate(`/${lang}/dashboard`) : navigate(`/${lang}/facebook-login`)}/>
                <h1>{t('Facebook page')}</h1>
            </PageTitle>
            <FacebookPageSubtitle>
                <p>{t("Select which facebook page you wish to advertise. If you do not see the page here make sure you gave the right permissions in the")} <Main_Plain text={t("previous step")} size="Small" noPadding onClick={()=>navigate(`/${lang}/facebook-login`)} /></p>
            </FacebookPageSubtitle>
            {!next?.cursor && next?.fbPages?.length > 10 ? 
            <SearchWrapper>
                <Google_Search
                    file={next?.fbPages}
                    fileKeys={['greeklish', 'greeklish_reversed']}
                    defaultValue={''}
                    sanitize={true}
                    placeholder={t('Facebook page')}
                    applyReverseFileProperties={[
                        {
                            originalKey: 'greeklish',
                            reversedKey: 'greeklish_reversed',
                        },
                    ]}
                    setResults={(data) => setSearchResults(data)}
                    minMatchCharacters={1}
                    maxMatchCharacters={50}
                    disabled={false}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                /> 
            </SearchWrapper>
                    : null
                }
              <CardsFull>
                    {searchResults?.input ? 
                    <>
                    {searchResults?.data?.map((page) => (
                        <Facebook_Page_Box
                            title={page?.name}
                            imgSmall={page?.picture}
                            onClick = {()=> saveInfoAndNavigate(page)}
                        />
                    ))}
                    </>
                        : 
                        <>
                        {next?.fbPages?.map((page) => (
                            <Facebook_Page_Box
                                title={page?.name}
                                imgSmall={page?.picture}
                                onClick = {()=> saveInfoAndNavigate(page)}
                            />
                        ))}
                    </>
                }
                </CardsFull>
                {loading ? 
                    <Full_Card_Loader />
                : null  }
        </PageAlwaysVisible>
    </PageFull>
  )
}
