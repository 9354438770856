import React, { useContext, useEffect, useState } from 'react'
import { AdMockupCaption, AdMockupImage, AdMockupPage } from '../../../styles/components/AdMockup'
import Fb_Carousel from '../../../library/fbCarousel/Fb_Carousel'
import styled from 'styled-components'
import { AlertContext, device } from 'monica-alexandria'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import q from '../../../services/api/Quests';
import ReactPlayer from 'react-player'
import { alertMessage } from '../../../utils/messagesAlerts'
import { Carousel_ } from '../../../components/questionnaireB/Carousel_'


const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const DesktopFeedProducts = styled.div`
  max-width: 50rem;
  width: 100%;
  padding-bottom: 1rem;
  box-shadow: ${p => p.theme.out};
  border-radius: var(--smallRadius);
  margin-top: 2rem;


  @media ${device.sm}{
      width: 30rem;
  }

  .carousel.carousel-slider .control-arrow{
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    background: var(--greyDark) !important;
    margin: 1rem;
    margin-top: 50%;
    transform: translate(0%, -50%);
  }

  .slide{
    min-width: calc(100% - 20rem) !important;
    margin: .5rem !important;
    transform: translateX(20rem);
  }
`

const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 0rem;
    border-top: 1px solid var(--grey);
`

const AdMockupActionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .fbLike{
        width: 1.8rem;
        fill: #606770;
        
    }

    .fbComment {
        width: 1.8rem;
        stroke: #606770;
        stroke-width: 1.2;
        fill: none;
    }   

    .fbShare{
        width: 3.2rem;
        stroke: #606770;
        stroke-width: 1.2;
        fill: none;
    }
    h5{
        color: #606770;
    }
`

const CarouselProducts = styled.div`

`
export const Desktop_Feed_Products = (props) => {

    const {t} = useTranslation();

    const {alerts, setAlerts} = useContext(AlertContext);
    const [products, setProducts] = useState()
    const [loading, setLoading] = useState(false)

    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

  
  return (
    <DesktopFeedProducts>
      <AdMockupPage>
        <img src={props?.questIdInUrl ? props?.adInfo?.facebookPagePicture : facebook_page?.picture} />
        <div>
            <h3>{props?.questIdInUrl ? props?.adInfo?.facebookPageName : facebook_page?.name}</h3>
            <p>{t('Sponsored')}</p>
        </div>
      </AdMockupPage>
      <AdMockupCaption>
          <h5>{props?.adInfo?.caption}</h5>
      </AdMockupCaption>
      <AdMockupImage>
        {props?.single ? (
            <ReactPlayer
                url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                width="100%"
                height="100%"  //might need change
                controls={true} 
                playing={false}
                //config={facebookConfig}
            />
        ): <Carousel_ refreshWidth={Object.keys(props.products || [])}>

                <Fb_Carousel productTitleLength={25} adInfo={props?.adInfo}  products={props.products || []} loading={loading}/>
        </Carousel_> 
   
         }
      </AdMockupImage>
      {/* <AdMockupSection>
      </AdMockupSection> */}
          
         <AdMockupActions>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbLike' class_Icon='fbLike'/>
                <h6>Like</h6>
            </AdMockupActionItem>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbComment' class_Icon='fbComment'/>
                <h6>Comment</h6>
            </AdMockupActionItem>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbShare' class_Icon='fbShare' />
                <h6>Share</h6>
            </AdMockupActionItem>
        </AdMockupActions>
  </DesktopFeedProducts>
  )
}
