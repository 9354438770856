import { IconSvg, device } from 'monica-alexandria'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'


const InstagramMockupWrapper = styled.div`
    aspect-ratio: 9/16;
    max-width: 40rem; //normal
    width: 100vw; //temp
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    overflow: hidden;
    background: var(--white);

    @media ${device.sm}{
        width: 35rem;
    }

`

const AdMockupCtaTitle = styled.div`
    h6{
        color: var(--black);
    }
`
const AdMockButton = styled.div`
    svg{
        width: 1.4rem;
        fill: #777;
    }

`



const AdMockupSection = styled.div`
    background: var(--white); 
    border-radius: .5rem;
    padding: .5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const AdMockupAccount = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    background: linear-gradient(to bottom, #000 -100%, transparent);
    padding: 3rem 1.5rem 5rem 1.5rem;

    h1{
        color: var(--white);
        font-size: 2.5rem;
    }
    svg{
        width: 2.5rem;
        stroke: var(--white);
        stroke-width: 2;
        fill: none;
     
    }
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    h6{
        color: var(--white);
        font-size: 1.1rem;
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
    }
`

const AdMockupImage = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: .5rem;

    svg{
        stroke: var(--white);
        stroke-width: 2;
        fill: none;
        width: 2.6rem;
        height: 2.6rem;
    }

    .instaicon {
        width: 2.3rem;
    }

    svg:last-of-type{
        width: 1.5rem;

        fill: var(--white);
        stroke: none;
    }
`


const AdMockupLabel = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0 .5rem 0;
    gap: 0.5rem;
    background-color: #555;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    width: fit-content;

     svg{
        stroke: var(--white);
        stroke-width: 2;
        fill: none;
        width: 1rem;
    }

    h6{
        color: var(--white);
        font-size: 1rem;
    }
   
`

const AdMockupBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    background: linear-gradient(to top, #000 -30%, transparent);
    padding: 5rem 1.5rem 1rem 1.5rem;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 3rem;
`

const AdMockupBottomLeft = styled.div`
    width: 100%;
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';
export const Instagram_Mockup_Reels = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const {questId} = useParams();

  return (
    <InstagramMockupWrapper>
    
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupAccount>
            <h1>Reels</h1>
            <DailyprofitSvg Icon='Camera' />
        </AdMockupAccount>
    
        <AdMockupBottom>
            <AdMockupBottomLeft>
                <AdMockupAccountInfo>
                    <img src={questId ? props?.adInfo?.facebookPagePicture :facebook_page?.picture} />
                    <div>
                        <h6>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h6>
                    </div>
                    </AdMockupAccountInfo>
                <AdMockupSection>
                    <AdMockupCtaTitle>
                        <h6>{findLabelByValue(props.adInfo?.cta,t)}</h6>
                    </AdMockupCtaTitle>
                    <AdMockButton>
                        <IconSvg Icon='Next' />
                    </AdMockButton>
                </AdMockupSection>
                    <AdMockupLabel>
                        <DailyprofitSvg Icon='InstaMegaphone'  />
                        <h6>Sponsored</h6>
                    </AdMockupLabel>
            </AdMockupBottomLeft>
            <AdMockupActions>
                <DailyprofitSvg Icon='InstaHeart'  />
                <DailyprofitSvg Icon='InstaComment' class_Icon='instaicon' />
                <DailyprofitSvg Icon='InstaShare' class_Icon='instaicon' />
                <DailyprofitSvg Icon='InstaOptions' class_Icon='instaiconfill' />
            </AdMockupActions>
        </AdMockupBottom>
    </InstagramMockupWrapper>
  )
}
