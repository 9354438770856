import axios from "axios"
import { config } from "../../config"
import store from "../../redux/store"


const get_settings = (id) =>{
    return new Promise((resolve,reject) =>{
        console.log(id)
        axios.get(`${config.ERISED_BACKEND_URL}/users/${id}/getSettings`)
        .then(res => resolve(res))
        .catch((err) => {
            console.error('GET SETTINGS ERROR')
            resolve(false);
        })
    })
}

const get_user_images = (userId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getImages?userId=${userId}`)
        .then(res => {
            console.log('[getImages] >',res.data);
            resolve(res)
        })
        .catch(() => {
            console.error('[Error | getImages] > ')
            resolve(false);
        })
    })
}

const save_preferences = (userId) => {
    return new Promise((resolve, reject) => {
        

        const theme = store.getState().theme;
        const language = store.getState().selectedLanguage;
        const region = store.getState().country;

        axios.post(`${config.ERISED_BACKEND_URL}/users/savePreferences`, {
            data: {
                userId: userId,
                theme: theme,
                language: language,
                region: region
            }
        })
        .then(res => {
            console.log('[savePreferences] >',res.data);
            resolve(res)
        })
        .catch(() => {
            console.error('[Error | savePreferences] > ')
            resolve(false);
        })
    })
}

const get_preferences = (userId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getPreferences?userId=${userId}`)
        .then(res => {
            console.log('[getPreferences] >',res.data);
            resolve({
                theme: res?.data?.data?.theme ? res.data.data.theme : false,
                lang: res?.data?.data?.language ? res.data.data.language : false,
                region: res?.data?.data?.region ? res.data.data.region : false
            })
        })
        .catch(() => {
            console.error('[Error | getPreferences] > ')
            resolve(false);
        })
    })
}

const get_my_subscriptions = (userId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/getMySubscriptions?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}


export {
    get_settings, 
    get_user_images,
    save_preferences,
    get_preferences,
    get_my_subscriptions
};
