
import { AlertContext, Builder_, Filters_, Form, Grey_Link, Main_, PageAlwaysVisible, PageDesktopOnly, PageFull, PageSixty, PageTitle, Upload_Cover, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Landing_Form } from './Landing_Form'
import query from 'query-string'
import { LandingContext } from '../../context/LandingContext'
import { Landing_Header_Slider } from './Landing_Header_Slider'
import { useTranslation } from 'react-i18next'
import { Landing_Form_Slider } from './Landing_Form_Slider'
import { alertMessage } from '../../utils/messagesAlerts'
import { Landing_Image_Slider } from './Landing_Image_Slider'
import NoImage from '../../assets/landing/NoImage.jpg'
import NoImageGrey from '../../assets/landing/NoImageGrey.jpg'
import { Landing_Form_Preview } from './Landing_Form_Preview'
import a from '../../services/api/Ads'
import { Landing_Form_Edit_New } from './Landing_Form_Edit_New'
import { Laning_Form_Custom_Fields } from './Landing_Form_Custom_Fields'
import store from '../../redux/store'
import { useParams } from 'react-router-dom'
import FbLogo from "../../assets/dashboard/FbLogo.png"


const Landing = styled(PageFull)`
  
  .mobNav {
      display: none;

        @media ${device.md} {
          display: flex;
        }
    }

    .desktopNav{
      @media ${device.md} {
        display: none;
      }
    }
`

const LandingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40%;
  grid-gap: 4rem;

  @media ${device.md} {
    grid-template-columns: 1fr;
  }
`

const FormWrapper = styled.div`
  margin-bottom: 4rem;
`

const LandingFormPreview = styled.div`
  position: sticky;
  top: 15rem;
  padding-bottom: 4rem;
`
const InstanFormWrapper = styled.div`
    background: var(--grey);
    border-radius: var(--smallRadius);
    overflow: hidden;

    @media ${device.md} {
        margin-top: 2rem;
    }
`
const InstantFormCoverImg = styled.div`
    img{
      height: 215px;
      width: 100%;
      object-fit: cover;
     }
`

const InstantForm = styled.div`
    transform: translateY(-4rem);
    border-radius: var(--normalRadius);
    background: var(--white);
    margin : 0rem 4rem;
`

const InstantFormHeader = styled.div`
    padding: 0rem 2rem;
    h3{
        padding-bottom: 1rem;
    }
    h3,p{
        text-align: center;
        color: var(--black);
    }

    .defaultText{
        opacity: 0.7;
        font-style: italic;
    }
`

const InstantFormPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-4rem);
    img{
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        border: 1px solid var(--white);
    }
    p{
        padding-top: 1rem;
        color: var(--black);
        text-align: center;
    }

`

const LandingFormMobNav = styled.div`
   display: none;

    @media ${device.md} {
        display: block;
    }
`

const PageTitleLeft = styled.div`
  display: flex;
  align-items: center;
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export default function Landing_New(props) {

  const queryParams = query.parse(window.location.search);
  const {landing, setLanding} = useContext(LandingContext) || {};
  const {t} = useTranslation();

  let facebookPagePicture = store.getState()?.metaSource?.page_selection_info?.picture || false;
  let facebookPageName = store.getState()?.metaSource?.page_selection_info?.name || false;

  const {alerts,setAlerts} = useContext(AlertContext);

  const [customField, setCustomField] = useState(landing?.customFormField?.label);

  const params = useParams();
  const [imgErrors, setImgErrors] = useState([]);
  const [adImg, setAdImg] = useState('')


  const upload_pic = async (files) => {

    if (files?.length && files[0]?.preview) {
    
            setLanding({
              ...landing,
              img:`${files[0]?.preview}`,
              file: files[0]
            });    
        }  
        setImgErrors([])
  };

  const delete_pic = async (files) => {
    if (files?.length ) {
       //for later use
        //alertMessage(alerts, setAlerts,t(`Deleting image...`), 'Loading');
            setLanding((prevLanding) => ({
              ...prevLanding,
              img:''
            }));    
        } 
        setImgErrors([]) 
  };

  const handleBoomRejected = (type) => {
    let reason = '';
    switch (type) {
        case 'fileMimetype': {
            reason = (t('File must be png or jpeg'));
            break;
        }
        case 'fileSize': {
            reason = (t('Image must be less than 3MB'));
            break;
        }
    }
    setImgErrors( [(t('Could not upload image'))+": "+reason])
   // alertMessage(alerts, setAlerts, (t('Could not upload image. Reason'))+": "+reason,'Error');
    alertMessage(alerts, setAlerts, (t('Could not upload image'))+": "+reason,'Error');
  };



  const get_my_ad = (questId)=> {

    a.getMyAd(landing?.questId)
    .then(res => {
        if (res?.data?.code == "A2002") {
          // const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}`
          const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}` ;
          //setLanding(load_my_landing(image, 'L2002'));
          setAdImg(image)
          // setLanding({
          //   ...landing,
          //    image: image,
          //    code: 'L2002'
          // });   
           console.log('res?.data?.ad', res?.data?.ad);
        }
    })
  }


  useEffect(() => {
    get_my_ad(landing?.questId)
  }, []);


  return (
    <Landing>
      <PageTitle>
        <Grey_Link iconLeft='Back' onClick={props.onBack} noPadding/>
        {params.questId?
            <h1>{t("Edit form")}</h1>
          : <h1>{t("Create form")}</h1>
        }
      </PageTitle>
      <Filters_>
          <Main_ className='mobNav' text={t('Next')} iconRight="Next" onClick={props.onNext}/>
          <Main_  className='desktopNav' text={t('Save')} iconLeft="Save" onClick = {props.onSave} />
      </Filters_>
      <LandingGrid>
      <PageAlwaysVisible>
        <FormWrapper>
          <Form>
            <Landing_Header_Slider 
              title={landing?.header?.title} 
              subtitle={landing?.header?.subtitle}
            />
          </Form>
        </FormWrapper>
          <Form>
            <Landing_Form_Edit_New 
              customField={customField} 
              setCustomField={setCustomField} 
            />
          </Form>
          <Laning_Form_Custom_Fields />
      </PageAlwaysVisible>
      <PageDesktopOnly>
        {/* landing form preview component */}
        <LandingFormPreview>     
          <InstanFormWrapper>
            <InstantFormCoverImg>
                <img src={adImg ? adImg : NoImageGrey} />
            </InstantFormCoverImg>
            <InstantForm> 
                <InstantFormPage>
                    <img src={FbLogo} />
                    <p>{facebookPageName}</p>
                </InstantFormPage>
                <InstantFormHeader>
                    {landing?.header?.title ?
                        <h3>{landing?.header?.title}</h3>  
                        : <h3 className='defaultText'> { (t('Limited time offer!'))}</h3>  }
                    {landing?.header?.subtitle ?
                      <p> {landing?.header?.subtitle } </p>:
                      <p className='defaultText'>  {t("Fill in the form and get informed now")} </p>
                    }
                </InstantFormHeader>
                <Landing_Form 
                  landingNew 
                  disableForm={props.disableForm} 
                  viewMode={true}
                />
            </InstantForm>  
          </InstanFormWrapper>
        </LandingFormPreview>
        {/* landing form preview component */}

        {/* <Landing_Form_Preview landingNew adImg={props.adImg} disableForm={props.disableForm} viewMode={true}/> */}
      </PageDesktopOnly>
      </LandingGrid>
    </Landing>
  )
}
