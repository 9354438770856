import { Card, Focus_, Focus_Plain, Grey_, Grey_Link, Main_Plain, Red_, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prettifyDates } from '../../../../helpers/prettifiers'

const SubscriptionCard = styled(Card)`
  background: ${p => p.theme.low};
  padding: 2rem;
`

const SubscriptionCardBot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 2rem;
`

const SubscriptionCardActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
`

const SubscriptionCardInfo = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  @media ${device.lg} {
    flex-direction: column;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column;
  }
`

const SubscriptionCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin-top: 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`

const SubscriptionCardLeft = styled.div``

const SubscriptionCardAmountPaid = styled.div`
  display: flex;
  align-items: baseline;

  h1{
    font-size: 4rem;
    text-align: center;
  }
`

export default function Subscription_Card(props) {

  const {t} = useTranslation();

  const SubscriptionStatus = {
    active: (t("active")),
    past_due: (t("payment failed"))
};

  return (
    <SubscriptionCard>
      <SubscriptionCardInfo>
        <SubscriptionCardLeft>
          <SubscriptionCardAmountPaid>
            <h1>€ {props.amountPaid}</h1>
            <h5>,00</h5>
          </SubscriptionCardAmountPaid>
          <SubscriptionCardGrid>
            <p>{t("Payment interval")}:</p>
            <h5>{t("30 days")}</h5>
            <p>{t("Subscription Status")}:</p>
            <h5>{SubscriptionStatus[props.subscriptionStatus]}</h5>
            <p>{t("Next payment")}:</p>
            <h5>{!props.subscriptionCancelOnPeriodEnd ? prettifyDates(props.subscriptionNextPayment * 1000) : (t("Next scheduled payment canceled"))}</h5>
            {props?.amountTopUp ? <>
              <p>{t("Next invoice is set to")}:</p>
              <h5>€ {props.amountTopUp},00</h5>
            </> : null}
          </SubscriptionCardGrid>
        </SubscriptionCardLeft>
       {!props.subscriptionCancelOnPeriodEnd ? <SubscriptionCardActions>
          <Focus_ noPadding text={t("Increase budget")} size="small" iconLeft="Add" onClick={props.onSubscriptionTopup} />
        </SubscriptionCardActions> :null}
      </SubscriptionCardInfo>
      <SubscriptionCardBot>
        {/* <Grey_Link text={t("View subscription details")} iconLeft="Group" noPadding size="small" onClick={props.onSubscriptionDetails}/> */}
        {props.subscriptionCancelOnPeriodEnd ? 
        <Focus_ size="small" noPadding iconLeft="Swap" text={t("Renew subscription")}  onClick={props?.onSubscriptionRenew}
/>
         :
         <Red_ size="small" noPadding iconLeft="Delete" text={t("Cancel subscription")} onClick={props.onSubscriptionCancel} />
        }
      </SubscriptionCardBot>
    </SubscriptionCard>
  )
}
