   
export function getAdImageUrl(quest){

    let minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

    if (quest?.adInfo?.thumbnail && quest?.adInfo?.thumbnail !== 'default.jpg') {
        return quest?.adInfo?.thumbnail;
    }

    if (quest?.questionnaires[0]?.answer==='eshop' && quest?.Meta?.mode ==='productCatalog' && quest?.Meta?.productSetImg) {
        return quest?.Meta?.productSetImg;
    }

    if (quest?.Meta?.mode !=='productCatalog' && quest?.adInfo?.visualElement) {
        return `${minioUrl}${quest?.userId}/${quest?.adInfo?.visualElement}`;
    }

    return false;
};