import React from 'react'
import styled, { keyframes } from 'styled-components'
import store from '../../redux/store'
import { device } from 'monica-alexandria'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
import NoImage from '../../assets/ad/NoImgAd.jpg'
import { AdMockButton, AdMockupActions, AdMockupCaption, AdMockupImage, AdMockupPage, AdMockupSection, AdMockupTitle } from '../../styles/components/AdMockup'
import { findLabelByValue } from '../../utils/findCtaLabel'
import { useParams } from 'react-router-dom'

const PreviewAdMockupAnimation = keyframes`
  0% {
    box-shadow: -3px -3px 10px var(--main);
  }
  25% {
    box-shadow: 3px -3px 10px var(--main);
  }
  50% {
    box-shadow: 3px 3px 10px var(--main);
  }
  75% {
    box-shadow: -3px 3px 10px var(--main);
  }
  100% {
    box-shadow: -3px -3px 10px var(--main);
  }
`;

const PreviewAdMockup = styled.div`
    max-width: 50rem;
    width: 100%;
    padding-bottom: 1rem;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    animation: ${PreviewAdMockupAnimation} 4s infinite linear;
    margin-top: 2rem;


    @media ${device.sm}{
        width: 30rem;
    }

`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Preview_Ad_Mockup = (props) => {

    const {t} = useTranslation();
    const {questId} = useParams();

    function renderThumbnail(){
        if (props?.adInfo?.thumbnail === 'default.jpg') return false
        else if  (!props?.adInfo?.thumbnail) return false
        else if (props?.adInfo?.thumbnail && props?.adInfo?.thumbnail === 'default.jpg'){
        <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> }
    }

    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

  return (
    <PreviewAdMockup>
        <AdMockupPage>
            <img src={questId ? props?.adInfo?.facebookPagePicture : facebook_page?.picture} />
            <div>
                <h3>{questId ? props?.adInfo?.facebookPageName : facebook_page?.name}</h3>
                <p>{t('Sponsored')}</p>
            </div>
        </AdMockupPage>
        <AdMockupCaption>
            <h5>{props?.adInfo?.caption}</h5>
        </AdMockupCaption>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light = {renderThumbnail()}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                    //config={facebookConfig}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImage)} />
            ) }
        </AdMockupImage>
        <AdMockupSection>
        <AdMockupTitle>
            <p>landing.url</p>
            <h3>{props.adInfo?.title}</h3>
        </AdMockupTitle>
        <AdMockButton>
            <h6>{findLabelByValue(props.adInfo?.cta, t)}</h6>
        </AdMockButton>
        </AdMockupSection>
        <AdMockupActions>
            <h6>{t('Like')}</h6>
            <h6>{t('Comment')}</h6>
            <h6>{t('Share')}</h6>
        </AdMockupActions>
    </PreviewAdMockup>
  )
}
