import { AlertContext, Alert_, Builder_, Input_, Main_Plain, Steps_Actions } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { StepContext } from '../../context/StepContext';
import q from '../../services/api/Quests'
import { alertMessage } from '../../utils/messagesAlerts';



const EshopConfigureUrl = styled.div`

`

export default function Eshop_Configure_Url(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const {alerts, setAlerts} = useContext(AlertContext);

    const { step, setStep } = useContext(StepContext);

    const {questId} = useParams();
    const [messages, setMessages] = useState({
      errors: [],
      helpers: [],
      successes: [],
    });

    const validateURL = (url) => {
      let helpers = [];
      let successes = [];
  
      // Regular expression to match a valid URL format
      const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  
      if (url !== '') {
          if (!urlRegex.test(url)) {
              helpers.push(t("Correct URL format"));
          } else {
              successes.push("URL is valid.");
          }
      }
      
      setMessages({...messages, helpers: helpers, successes: successes });

      props.setQuest({...props?.quest, eShopUrl:url})
  };

  const validateAndEditUrl = () => {
    // Ensure the URL starts with 'https://'
    let updatedURL = props?.quest?.eShopUrl;
    if (updatedURL.startsWith('http://')) {
      updatedURL = updatedURL.replace('http://', 'https://');
    } else if (!updatedURL.startsWith('https://')) {
        updatedURL = 'https://' + updatedURL;
    }
    return updatedURL;
};


    const editQuest = () => {
      if (messages.helpers?.length) {
        setMessages({errors: messages?.helpers, successes: [], helpers:[]})
        props.setQuest({...props?.quest, eShopUrl:''})
      }else if (!props?.quest?.eShopUrl){
        alertMessage(alerts, setAlerts, (t('Url must not be empty')), 'Error');
      } else {
        const updatedURL = validateAndEditUrl();
        q.editQuest(props?.quest?.questionnaires, props?.quest?._id, updatedURL)

          .then(res => {
            if (questId && res?.data?.code == 'Q2003'){
              navigate(`/${lang}/dashboard`);
              if(props.originalUrl != props?.quest?.eShopUrl) alertMessage(alerts, setAlerts, (t('Url changed successfully')), 'Success');
            } 
            //else if (!questId && res?.data?.code == 'Q2003') navigate(`/${lang}/ad-helper`);
            else if (!questId && res?.data?.code == 'Q2003') setStep(1);
            else {
              console.log('error')
              alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
          })
      }
    }

    console.log('QUEST', props?.quest);
  return (
    <EshopConfigureUrl>
        <Alert_ messages={alerts} duration={3000} />
        <h3>{t("Submit your eshop link")}</h3>
        <Builder_ title={t("Enter the url of your eshop or your product")}>
            <Input_ 
              defaultValue={props?.quest?.eShopUrl || ''} 
              placeholder="eshop url" 
              value={props.quest?.eShopUrl}
              helpers={messages?.helpers}
              successes={messages?.successes}
              errors={messages?.errors}
              onChange = {(e)=> validateURL(e.target.value) }
            />
        </Builder_>
        <Steps_Actions
        icon_Grey="Back"
        icon_Main="Next"
        onClick_Grey={()=> questId ? navigate(`/${lang}/dashboard`) : navigate(`/${lang}/questionnaire/beta`)}
        onClick_Main={() => editQuest()}
        text_Grey={t("Back")}
        text_Main={t("Next")}
        />
    </EshopConfigureUrl>
  )
}
