import { Button, Grey_Link, LimitCharacters, Main_Plain, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Heart from '../../../../assets/ad/preview/Heart.png'
import Like from '../../../../assets/ad/preview/Like.png'
import Laugh from '../../../../assets/ad/preview/Laugh.png'
import IconLike from '../../../../assets/ad/preview/IconLike.png'
import IconComment from '../../../../assets/ad/preview/IconComment.png'
import IconShare from '../../../../assets/ad/preview/IconShare.png'

const EditAd = styled.div`
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
    border: 2px solid ${p => p.theme.low};
`

const EditAdTitle = styled.h5`
    padding: 1rem 2rem;
`

const EditAdContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 4rem);
`

const EditAdWrapper = styled.div`
    background: ${p => p.theme.background};
    max-width: 40rem;
    margin: 0 auto;
    border-radius: var(--smallRadius);
`

const EditAdTop = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem .5rem 0 .5rem;

    img{
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        border: 1px solid ${p => p.theme.color};
        background: ${p =>p.theme.color};
    }
`

const EditAdTopText = styled.div``

const EditAdTopPage = styled.h5`
    font-size: 1.2rem;
`

const EditAdTopSponsored = styled.p`
    font-size: 1rem;
`

const EditAdCaption = styled.div`
    padding: 0rem .5rem;
    p{
        font-size: 1.2rem;
        padding: 1rem 0;
    }
`

const EditAdImage = styled.div`
    width: 100%;
    
    img{
        width: 100%;
        aspect-ratio: 7/5;
        object-fit: cover;
    }
`

const EditAdFooter = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: .5rem;
    align-items: flex-start;
    background-color: ${p => p.theme.mid};
    border-bottom: 1px solid ${p => p.theme.high};

    button{
        background: transparent;
        border-radius: .5rem;
        border: 1px solid ${p => p.theme.color};
        pointer-events: none;
        
        span{
            font-size: 1.2rem;
            color: ${p => p.theme.color};
        }
    }
`

const EditAdFooterWrapper = styled.div`

    p{
        font-size: 1.2rem;
        text-transform: uppercase;
    }
`

const EditAdFooterBars = styled.div``

const EditAdInteract = styled.div`
    display: flex;
    justify-content: space-between;
    padding: .5rem;
`

const EditAdInteractLeft = styled.div`
    display: flex;
    gap: 1rem;
    margin: .5rem 0;
    
    p{
        font-size: 1.2rem;
    }
`

const EditAdEmoji = styled.div`

    img{
        width: 1.5rem;
    }
`

const EditAdSep = styled.div`
    height: 2px;
    width: calc(100% - 1rem);
    background: var(--greyDark);
    margin: 0rem .5rem;
`

const EditAdClick = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem 0;
`



const EditAdButton = styled.div`
    display: flex;
    justify-content: center;
    gap: .5rem;
    align-items: center;

    img{
        width: 1.5rem;
        margin-bottom: 4px;
    }

    h5{
        font-size: 1.2rem;
        color: var(--greyDark);
    }
`

const EditAdActions = styled.div`
    display: flex;
    gap: 2rem;
    padding: 1rem 2rem;
`


export default function Edit_Ad(props) {

    const {t} = useTranslation();

  return (
    <EditAd>
        <EditAdTitle>{t("Ad placement")}</EditAdTitle>
        <EditAdContainer>
            <EditAdWrapper>
                <EditAdTop>
                    <img src={props.adAvatar} />
                    <EditAdTopText>
                        <EditAdTopPage>{props.adPage}</EditAdTopPage>
                        <EditAdTopSponsored>{t("Sponsored")}</EditAdTopSponsored>
                    </EditAdTopText>
                </EditAdTop>
                <EditAdCaption>
                    <p>{LimitCharacters(props.adCaption , 100)}</p>
                </EditAdCaption>
                <EditAdImage>
                    <img src={props.adImg} />
                </EditAdImage>
                <EditAdFooter>
                    <EditAdFooterWrapper>
                        {props.type === 'eshop' ?
                            <p>{LimitCharacters(props.adUrl, 25)} || questad.doitforme.eu</p>
                        :props.type === 'services' ?
                            <p>questad.doitforme.eu</p>
                        :
                            <EditAdFooterBars>
                                {props.adInstagram ?
                                    <p>instagram.com</p>
                                :
                                    <p>facebook.com</p>
                                }
                            </EditAdFooterBars>
                        }
                        <h6>{LimitCharacters(props.adTitle , 25)}</h6>
                    </EditAdFooterWrapper>
                    <Button>
                        <span>{props.adCta}</span>
                    </Button>
                </EditAdFooter>
                <EditAdInteract>
                    <EditAdInteractLeft>
                        <EditAdEmoji>
                            <img src={Like} />
                            <img src={Heart} />
                            <img src={Laugh} />
                        </EditAdEmoji>
                        <p>{t("Comments")}</p>
                        <p>{t("Shares")}</p>
                    </EditAdInteractLeft>
                    
                </EditAdInteract>
                <EditAdSep></EditAdSep>
                <EditAdClick>
                    <EditAdButton>
                        <img src={IconLike} />
                        <h5>{t("Like")}</h5>
                    </EditAdButton>
                    <EditAdButton>
                        <img src={IconComment} />
                        <h5>{t("Comment")}</h5>
                    </EditAdButton>
                    <EditAdButton>
                        <img src={IconShare} />
                        <h5>{t("Share")}</h5>
                    </EditAdButton>
                </EditAdClick>
            </EditAdWrapper>
            <EditAdActions>
                <Grey_Link iconLeft="EyeOpen" text={t("View")} noPadding size="small" onClick={props.onAdView}/>
                <Main_Plain iconLeft="Edit" text={t("Edit")} noPadding size="small" onClick={props.onAdEdit}/>
            </EditAdActions>
        </EditAdContainer>
    </EditAd>
  )
}
