import { Actions_, AlertContext, Builder_, Focus_, Grey_, Input_, Main_, Main_Text_Loader } from 'monica-alexandria';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { alertMessage } from '../../../../utils/messagesAlerts';


import q  from '../../../../services/api/Quests';
import SUCCESS_PAYMENT from '../../../SUCCESS_PAYMENT.js/SUCCESS_PAYMENT';
const clients = require('../../../../config/index').config

const SubscriptionTopUp = styled.div``

export default function Subscription_Topup_Archived(props) {

    const {t} = useTranslation();
    const {lang} = useParams();
    const navigate = useNavigate();

    const [loading,setLoading] = useState(false);
    const [newBudget, setNewBudget] = useState(props.totalBudget);
    const { alerts, setAlerts } = useContext(AlertContext);
    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });

    const handleBudgetChange = (e) => {
        // Check if the entered value is a valid number (float or integer)

        let helpers = [];
        let successes = [];

        const inputValue = e.target.value; 

        if (inputValue != '') {
            if(inputValue < 50 )  helpers.push(t('New budget must be more than current')) 
            if (inputValue >= 50) successes.push(t('Minimum budget is 50€/month'))

            if (!Number.isInteger(Number(inputValue))) helpers.push(t('Do not include decimals')) 
            else successes.push(t('Do not include decimals'))

            if(inputValue > 10000 )  helpers.push(t('Maximum budget is 10000€/month')) 
            else if (inputValue <= 10000) successes.push(t('Maximum budget is 10000€/month'))
        }
            setNewBudget(inputValue.trim());
            setMessages({...messages, helpers: helpers, successes: successes})
      };

      const proceed_with_new_budget = () =>{
        let budgeterrors = [];  
    
        if (messages.helpers?.length !== 0) {
            setNewBudget('');
            budgeterrors = messages.helpers;
        }
    
        if (newBudget === '')  alertMessage(alerts, setAlerts, (t('Monthly budget must not be empty.')), 'Error')
        else if (newBudget < 50 || !Number.isInteger(Number(newBudget)) ) {
            setMessages({ ...messages, errors: budgeterrors, helpers:[] });
        } else {
            // call some backend function
            setLoading(true)    
            q.topUpQuestBudgetandRestart(props.selectedQuestId, props?.customer, newBudget)
            .then( res => {
                console.log('res top up budget', res)
                setLoading(false)

                if (res?.data?.code === "Q2013" || res?.data?.code === "Q5013"){
                    console.log(res?.data)
                    window.location.assign(`${clients['griphook']}/${lang}/checkout-details-questad?category=dailyprofit&interval=month&quest=${props.selectedQuestId}`)
                }
                else  {
                    if(res?.data?.code==='SCC4000') alertMessage(alerts, setAlerts, (t('Something went wrong. Try again or contact support.')), 'Error')
                    else {
                        navigate(`/${lang}/success-payment?quest=${props.selectedQuestId}`)
                        alertMessage(alerts, setAlerts, (t('Quest Restarted!')), 'Success');
                    }
                }
            })
            .catch( error => {
                console.log(error)
                setLoading(false);
            })
            console.log("newBudget", newBudget)
        }
      }

  console.log(' ARCHIVED  props', props)

  if (loading) return <Main_Text_Loader text={t('We are processing your information...')}/>
  return (
    <SubscriptionTopUp>
        <Builder_ title={t("Set quest's budget")}></Builder_>
            <Input_ 
                placeholder={t("Quest's budget")}
                value={newBudget}
                onChange = {handleBudgetChange}
                errors={messages?.errors}
                helpers={messages?.helpers}
                successes={messages?.successes}
            />
        <Actions_>
            <Grey_ iconLeft="Xicon" text={t("Cancel")} onClick={props.onSubscriptionTopupCancel}/>
            <Focus_ iconRight="Next" text={t("Set budget")} onClick={proceed_with_new_budget}/>
        </Actions_>
    </SubscriptionTopUp>
  )
}
