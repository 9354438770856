import { Builder_, Button, Form, Grey_Link, IconSvg, Input_, Main_Plain, On_Click_Card, Red_ } from 'monica-alexandria';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LandingContext } from '../../context/LandingContext';

const LandingFormcustomFormField = styled.div``

const LandingFormcustomFormFieldContainer = styled.div`
    margin-top: 4rem;
`

const LandingFormcustomFormFieldWrapper = styled(Form)`
    padding-top: 2rem;
    border-top-left-radius: ${p => p.activeType === 'input' ? '0' : '3rem' };
    border-top-right-radius: ${p => p.activeType === 'input' ? '3rem' : '0' }; 
`


const LandingFormFields = styled.div`
    .FormField{
        margin-bottom: 1.5rem;
    }
`

const FormCustomFieldType = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

const FormCustomFieldTypeChoice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: ${p => p.activeType ? '0': '2rem'};
    border-bottom-right-radius: ${p => p.activeType ? '0': '2rem'};
    background: ${p => p.theme.low};
    cursor: pointer;
    margin: ${p => p.activeType ? '0rem 0rem' : '.5rem 1.5rem'};
    padding: ${p => p.activeType ? '1rem 2rem': '0rem 0rem'};

    span{
        transition: color .5s;
        font-family: 'fontMedium', sans-serif;
        font-size: 1.8rem;
        color: ${p => p.activeType ? p.theme.color : 'var(--greyDark)'};
    }

    &:hover{

        span{
            transition: color .5s;
            color: ${p => p.theme.color};
        }        
    }
`

const LandingFormcustomFormFieldTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const FormCustomInputType = styled.div`
`

const FormSuggestedQuestions = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;

    li{
        width: 100%;
        padding: 1.5rem 1rem;
        background: ${p => p.theme.background};
        border: 2px dashed var(--greyDark);
        transition-property: background, border;
        transition-duration: .5s;
        border-radius: var(--smallRadius);

        &:hover{
            background: ${p => p.theme.mid};
            border: 2px dashed transparent;
        }
    }
`


const FormMultipleChoice = styled.ul`
    margin-left: 8rem;
    padding-top: 1rem;

      
    li{
        padding-bottom: 0.5rem;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-content: space-between;
     
      
        gap:1rem;
       
       
    }
`
 const FormMultipleChoiceItem = styled.div`
    display: flex;
    align-items: center;
    /* svg{
            width: 1rem;
            fill: var(--main);
        } */
`

const LandingFormcustomFormFieldAdd = styled(Button)`
    margin-top: 4rem;
    background-color: ${p =>p.theme.low};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
    width: 100%;
    border-radius: var(--normalRadius);
    border: 2px dashed var(--greyDark);

    span{
        color: ${p => p.theme.color};
    }

    svg{
        fill: ${p => p.theme.color};
    }
`


export const Laning_Form_Custom_Fields = (props) => {
    const { t } = useTranslation();
    const { landing, setLanding } = useContext(LandingContext);

 
  
    const handleAddCustomField = () => {
      setLanding((prevState) => ({
        ...prevState,
        customFormField: [...prevState.customFormField, { label: '', choices: ['', ''], type: 'input' }]
      }));
    };
  
    const suggestions = [
      (t('What products are you interested in?')),
      (t('Which sessions are you interested in attending?')),
      (t('What industry do you work in?')),
      (t('What type of industry do you own?'))
    ];
  
    const handleAddChoice = (fieldIndex) => {
      setLanding((prevState) => {
        const newFields = [...prevState.customFormField];
        const newChoices = [...newFields[fieldIndex].choices, ''];
        newFields[fieldIndex].choices = newChoices;
        return { ...prevState, customFormField: newFields };
      });
    };
  
    const handleRemoveField = (fieldIndex) => {
      setLanding((prevState) => ({
        ...prevState,
        customFormField: prevState.customFormField.filter((_, i) => i !== fieldIndex)
      }));
    };
  
    const handleRemoveChoice = (fieldIndex, choiceIndex) => {
      setLanding((prevState) => {
        const newFields = [...prevState.customFormField];
        const newChoices = newFields[fieldIndex].choices.filter((_, i) => i !== choiceIndex);
        if (newChoices.length < 2) {
          return prevState; // Ensure at least two choices remain
        }
        newFields[fieldIndex].choices = newChoices;
        return { ...prevState, customFormField: newFields };
      });
    };
  
  
    const handleFieldChange = (fieldIndex, key, value) => {
      setLanding((prevState) => {
          const newFields = [...prevState.customFormField];
          newFields[fieldIndex][key] = value;
          if (key === 'type' && value === 'multiple') {
              newFields[fieldIndex].choices = ['', '']; // Ensure choices are reset for multiple type
          } else if (key === 'type' && value === 'input') {
            delete newFields[fieldIndex].choices;
          }
          return { ...prevState, customFormField: newFields };
      });
  };

    console.log('landing_____________', landing);
    return (
      <LandingFormcustomFormField>
        {landing?.customFormField?.map((field, i) => (
          <LandingFormcustomFormFieldContainer key={i}>
            <FormCustomFieldType>
              <FormCustomFieldTypeChoice activeType={field.type === 'input'} onClick={() => handleFieldChange(i, 'type', 'input')}>
                <span>
                  {t('Single question')}
                </span>
              </FormCustomFieldTypeChoice>
              <FormCustomFieldTypeChoice activeType={field.type === 'multiple'} onClick={() => handleFieldChange(i, 'type', 'multiple')}>
                <span>
                  {t('Multiple choice')}
                </span>
              </FormCustomFieldTypeChoice>
            </FormCustomFieldType>
            <LandingFormcustomFormFieldWrapper activeType={field.type}>
              <Builder_ title={
                <LandingFormcustomFormFieldTitle>
                  {landing.customFormField?.length > 1 ?
                    <Red_ noPadding iconLeft="Delete" onClick={() => handleRemoveField(i)} />
                    :
                    null
                  }
                  {`${t('Custom field #')}${i + 1}`}
                </LandingFormcustomFormFieldTitle>
              } >
                <LandingFormFields key={i}>
                  {field.type === 'input' ?
                    <FormCustomInputType>
                      {field?.label === '' ?
                        <FormSuggestedQuestions>
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                handleFieldChange(i, 'label', suggestion);
                              }}
                            >
                              <h6>{suggestion}</h6>
                            </li>
                          ))}
                        </FormSuggestedQuestions>
                        : null}
                      <Input_
                        required
                        value={field.label}
                        placeholder={t("Your custom question")}
                        onChange={(e) => handleFieldChange(i, 'label', e.target.value)}
                      />
                    </FormCustomInputType>
                    : field.type === 'multiple' ?
                      <div>
                        <Input_
                          required
                          value={field.label}
                          placeholder={t("Your custom question")}
                          onChange={(e) => handleFieldChange(i, 'label', e.target.value)}
                        />
                        <FormMultipleChoice>
                          {field.choices.map((choice, index) => (
                            <li key={index}>
                              <Input_
                                value={choice}
                                placeholder={`${t('Answer #')}${index + 1}`}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setLanding((prevState) => {
                                    const newFields = [...prevState.customFormField];
                                    const newChoices = [...newFields[i].choices];
                                    newChoices[index] = newValue; // Update the current input value
                                    newFields[i].choices = newChoices;
                                    return { ...prevState, customFormField: newFields };
                                  });
                                }}
                              />
                              <FormMultipleChoiceItem>
                                <Main_Plain
                                  iconLeft='Add'
                                  size='small'
                                  onClick={() => handleAddChoice(i)}
                                />
                                {(field.choices?.length > 2) && (
                                  <Red_
                                    size='small'
                                    iconLeft='Delete'
                                    onClick={() => handleRemoveChoice(i, index)}
                                  />
                                )}
                              </FormMultipleChoiceItem>
                            </li>
                          ))}
                        </FormMultipleChoice>
                      </div>
                      : null}
                </LandingFormFields>
              </Builder_>
            </LandingFormcustomFormFieldWrapper>
          </LandingFormcustomFormFieldContainer>
        ))}
        <LandingFormcustomFormFieldAdd size='large' onClick={handleAddCustomField}>
          <IconSvg Icon="Add" />
          <span>{t("New custom field")}</span>
        </LandingFormcustomFormFieldAdd>
      </LandingFormcustomFormField>
    )
  }