import {
  Dark_Plain,
  Dropdown_,
  Grey_Link,
  LimitCharacters,
  Main_Plain,
  White_Plain,
  device,
} from "monica-alexandria";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DashboardTop = styled.div``;

const DashboardTopActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media ${device.lg} {
    
    button{
      span{
        display: none;
      }
    }
  }

  @media ${device.md} {
    
    button{
      span{
        display: block;
      }
    }
  }

  @media ${device.sm} {
    
    button{
      span{
        display: none;
      }
    }
  }
`;

const DashboardTopHam = styled.div``;

const DashboardTopCurrent = styled.div`
  width: 100%;
  box-shadow: ${(p) => p.theme.out};
  border-radius: var(--normalRadius);
  border: 2px solid ${p => p.theme.mid};
`;

const DashboardTopName = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 1px solid var(--greyLight);
    background: var(--greyLight);
  }
`;

const DashboardTopNameText = styled.div``;

const DashbpardSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  div {
    height: 2px;
    width: 100%;
    background: var(--greyDark);
  }

  h4 {
    letter-spacing: 0.5rem;
    color: var(--greyDark);
    white-space: nowrap;
  }
`;

export default function Dashboard_Top(props) {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState(props.selectedQuestId);
  const [pageNameLimit, setPageNameLimit] = useState();
  const [categoryLimit, setCategoryLimit] = useState();

  const selectActiveQuest = (id) => {
    const selectedQuest = props.activeQuests?.find((item) => item?._id == id);
    props.onChangeActiveQuest(selectedQuest);
    setSelectedId(id);
  };

  useEffect(() => {
    setSelectedId(props.selectedQuestId);
  }, [props.selectedQuestId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 556) {
        setPageNameLimit(15);
        setCategoryLimit(15); 
      }
      else if (window.innerWidth < 768) {
        setPageNameLimit(20);
        setCategoryLimit(20); 
      } 
      else if (window.innerWidth < 992) {
        setPageNameLimit(26); 
        setCategoryLimit(26); 
      } 
      else if (window.innerWidth < 1200) {
        setPageNameLimit(32); 
        setCategoryLimit(32); 
      } 
      else if (window.innerWidth < 1440) {
        setPageNameLimit(35); 
        setCategoryLimit(35); 
      } 
      else if (window.innerWidth < 1800) {
        setPageNameLimit(46); 
        setCategoryLimit(46); 
      } 
      else {
        setPageNameLimit(50); 
        setCategoryLimit(50); 
      }
    };

    // Set initial values based on current window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <DashboardTop>
      <DashboardTopActions>
        {/* <DashboardTopHam>
          {!props.sidebar ? (
            <Main_Plain
              noPadding
              text={t("Show toolbar")}
              iconRight="Next"
              onClick={props.onClick}
            />
          ) : (
            <Main_Plain
              noPadding
              text={t("Hide toolbar")}
              iconLeft="Back"
              onClick={props.onClick}
            />
          )}
        </DashboardTopHam> */}
        {props.general || props.stats ? 
          null 
        : 
          <Grey_Link
            text={t("Home")}
            iconLeft="Back"
            noPadding
            onClick={props.onTopViewStats}
          />
        }
        {props.general ? null : (
          <DashboardTopCurrent>
            <Dropdown_>
              <select
                value={selectedId}
                onChange={(e) => selectActiveQuest(e.target.value)}
              >
                {props.activeQuests.map((quest, i) => (
                  <option value={quest._id} key={i}>
                    {LimitCharacters(quest?.Meta?.facebookPageName, pageNameLimit)} |{" "}
                    {quest?.questionnaires[0]?.answer}{" "}
                    {LimitCharacters(quest?.questionnaires[1]?.answer, categoryLimit)}
                  </option>
                ))}
              </select>
            </Dropdown_>
          </DashboardTopCurrent>
        )}
      </DashboardTopActions>
      {props.general ? null : (
        <DashboardTopName>
          <img src={props.avatar} />
          <DashboardTopNameText>
            <h3>{props.page}</h3>
            <p>
              {props.type} | {props.industry}
            </p>
          </DashboardTopNameText>
        </DashboardTopName>
      )}
      <DashbpardSection>
        <div></div>
        <h4>{props.section}</h4>
        <div></div>
      </DashbpardSection>
    </DashboardTop>
  );
}
