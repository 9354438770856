import axios from 'axios';

const protocol = process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_GRIPHOOK_BACKEND_HOST;
const port = process.env.REACT_APP_GRIPHOOK_BACKEND_PORT;

const get_payment_event = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/checkout/events`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET EVENT')
            resolve(false);
        })
    })
}


const cancelSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/subscriptions/cancel_subscription`, {
                subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const renewSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/subscriptions/undo_cancel_subscription`, {
            subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}
const paymentsEndpoints = {
    get_payment_event,
    cancelSubscription,
    renewSubscription
}




export default paymentsEndpoints;