import { AlertContext, Alert_, Main_Loader, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageSubtitle, PageTitle, Steps_ } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { StepContext } from "../../context/StepContext";
import Client_Location from '../../components/questionnaireB/Client_Location';
import Client_Interests from '../../components/questionnaireB/Client_Interests';
import Client_Age_Gender from '../../components/questionnaireB/Client_Age_Gender';
import Monthly_Budget from '../../components/questionnaireB/Monthly_Budget';
import styled from 'styled-components';
import { renderStepDescription } from './utils';
import store from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next'
import q from '../../services/api/Quests'
import questionnaireSvg from '../../library/imgs/questionnaire.svg'
import { Helmet } from 'react-helmet';
import { QuestionnaireSubtitle } from '../../styles/components/Questionnaires';
import { Client_Location_Map } from '../../components/questionnaireB/Client_Location_Map';
import { LocationsContext } from '../../context/LocationsContext';


const lang = i18n.language ? i18n.language : 'el';

const QuestionnaireDescription = styled.div`
  padding: 2rem;
`

const SelectedPage = styled.div`
  
`
export default function QUESTIONNAIRE_B() {
    const [step, setStep] = useState({step: 0, answers: []});
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);
    const [questionnaireB, setQuestionnaireB] = useState([]);
    const [loading, setLoading] = useState(false);
    const [draftQuest, setDraftQuest] = useState(false);
    const navigate = useNavigate();

    const [mapCenter, setMapCenter] = useState([39.16227, 23.49089]);
    const {locationsCtx, setLocationsCtx} = useContext(LocationsContext);

    const facebook_page = store.getState().metaSource?.page_selection_info;


  const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
    question: (t(`${questionnaireB[0]?.question}`)),
    type: `${questionnaireB[0]?.type}`,
    answer: questionnaireB[0]?.answer || []
  })

    const check_if_draft_exists = () =>{
      q.getDraft()
      .then(res => { 
        console.error('draft',res?.data?.data)
        if(res?.data?.data) {
          setQuestionnaireB(res?.data?.data?.questionnaires.slice(2))
          setDraftQuest(res?.data?.data?._id)
          setLoading(false)
          console.log('DATAAAA YO', res?.data);
        }     
        else {
          q.getQuestionnaireB()
            .then(data=> {
              console.error('get q B', data)
              setQuestionnaireB(data?.data?.data)
              setLoading(false)
              console.log('DATAAAA YO', data);

            })
            .catch(err => console.log(err))
          }
      })
      .catch(err => console.log(err))
    }

    useEffect(() => {
      
      // TODO: not totally correct for the future
      const questionnaireA = store.getState().answers;
      console.log('questionnaireA', questionnaireA);
      
      if (!questionnaireA) navigate(`/${lang}/questionnaire/alpha/`)
      else check_if_draft_exists();
    }, [])

    useEffect(() =>{
      if(questionnaireB?.length<0) setLoading(true)
    }, [questionnaireB])

    console.log('questionnaireB', questionnaireB)
    console.log('STEP', step);
   // console.log('locations!!!', locations);
  if(loading || !questionnaireB?.length) return <Main_Loader />
  else return (
    <PageForty>
      <Helmet>
        <title>Questad | Questionnaire: Audience</title>
      </Helmet>
        <Alert_
            messages={alerts}
            duration={6000}
        />
      <PageAlwaysVisible>
        <PageTitle>
          <div>
            <h1>{t("Run Ads on Facebook & Instagram")}</h1>
            <QuestionnaireSubtitle>
              <img src={facebook_page?.picture} />
              <p>{facebook_page?.name}</p>
            </QuestionnaireSubtitle>
          </div>
        </PageTitle>
        <StepContext.Provider value={{ step, setStep }}>
            <Steps_
              currentStep={step?.step}
              steps={[
                <Client_Location Q1 = {questionnaireB[0]} stepInfo={stepInfo} setStepInfo={setStepInfo} mapCenter={mapCenter} setMapCenter={setMapCenter}/>,
                <Client_Interests Q2 = {questionnaireB[1]}/> ,
                <Client_Age_Gender Q3 = {questionnaireB[2]}/>,
                <Monthly_Budget Q4 = {questionnaireB[3]} questId={draftQuest} setLoading={(status) => setLoading(status)}/>,
              ]}
            />
        </StepContext.Provider>
      </PageAlwaysVisible>
      <PageDesktopOnly>
        {step.step === 0 ?
          <Client_Location_Map locations={locationsCtx} mapCenter={mapCenter} setMapCenter={setMapCenter}/>
        :<PageIllu>
          <img src={questionnaireSvg} />
        </PageIllu>}
      </PageDesktopOnly>
    </PageForty>
  )
}
