import React from 'react';
import styled from 'styled-components';
import { Card, device, H2, H3, h6, P } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const HomeQa = styled.section`
    background: ${(p) => p.theme.low};
    h2 {
        text-align: center;
    }
`;

const HomeQaGrid = styled.div`
    padding: 0 10rem;
    max-width: 100rem;
    margin: 0 auto;

    @media ${device.md} {
        padding: 0 2rem;
    }
`;

const HomeQaBox = styled(Card)`
    padding: 2rem;
    position: relative;
    overflow: unset;
    background: ${(p) => p.theme.background};
    margin-top: 2rem;
`;

const HomeQaQ = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--main));
    transform: translate(-4rem, -4rem) rotate(-1deg);

    h3 {
        color: var(--white);
    }
`;

const HomeQaA = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--focus));
    transform: translate(4rem, 0) rotate(1deg);

    p {
        color: var(--white);
        margin-top: 2rem;
    }

    h4 {
        color: var(--white);
        margin-bottom: 1rem;
    }

    h6 {
        color: var(--white);
        font-size: 1.8rem;
        font-family: 'fontLight';
    }
`;

export default function Home_Qa() {

    const {t} = useTranslation()

    return (
        <HomeQa>
            <H2>{t("Q&A")}</H2>
            <HomeQaGrid>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Do I Pay a Single Fee Every Month?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, you pay just one monthly fee for using our platform. There are no hidden charges.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Is the Monthly Charge on My Card Including Your Subscription?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, the amount charged to your card includes the subscription fee for using our platform.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Can I Get a Refund?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("  You can get a refund within the first 14 days on your subscription fee and any unspent ad budget. However, funds already spent on Meta or Google Ads cannot be refunded.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Is There Chat Support Available?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, we offer chat support. Any queries you have will be turned into tickets using your registered email.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Can I Control My Ads Through QuestAd?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Absolutely! You can edit the visuals and captions of your ads through QuestAd.")}</h6>
                    </HomeQaA>
                </HomeQaBox>

                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("Can I Stop My Subscription Immediately?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, you can stop your subscription at any time. QuestAd will ask if you want your ads to keep running for the remaining period of your subscription. If you choose to stop your ads, you can only reactivate them during your subscription days. The ad budget will continue to be used for the active days.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
                <HomeQaBox>
                    <HomeQaQ>
                        <H3>{t("How Does QuestAd Help Simplify Ad Management for Small Businesses?")}</H3>
                    </HomeQaQ>
                    <HomeQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("QuestAd automates and simplifies the ad management process, making it easy for small businesses to run and optimize campaigns without needing extensive advertising expertise.")}</h6>
                    </HomeQaA>
                </HomeQaBox>
            </HomeQaGrid>
        </HomeQa>
    );
}
