import React, { useContext } from 'react'
import styled from 'styled-components'
import { Cta_, Focus_, Main_, device } from 'monica-alexandria'
import { H5 } from '../../../styles/components/HomeText'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import homeAutomation from '../../../assets/home/homeAutomation.svg'
import { hasQuestAdSubscription } from '../utils'
import { AuthContext } from '../../../services/authentication/Guard'

const HomeHeader = styled.div``

const HomeHeaderWrapper = styled.div`
  margin: 2rem 4rem;
  background: ${p => p.theme.low};
  box-shadow: ${p => p.theme.outFocus};
  border-radius: var(--normalRadius);
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  /* display: flex;
  justify-content: center; */

  @media ${device.lg} {
    grid-template-columns: 1fr;
    }

  @media ${device.sm} {
    margin: 2rem 2rem;
    }
`

const HomeHeaderImg = styled.img`
  position: absolute;
  width: 50%;
  max-width: 60rem;
  right: 5%;
  top: 2rem;

  @media ${device.lg} {
    position: static;
    width: 100%;
    margin: 0 auto;
    transform: translateY(8rem);
    height:100%;
    }
    @media ${device.md} {
      width: 90%;
      max-width: 50rem;
      transform: translateY(1rem);
    }
`

const HomeHeaderHero = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 10rem 4rem;

  h1{
    font-size: 5rem;
    line-height: 1;
    margin-bottom: 2rem;
  //  text-align: center;
  }
  h5{
    //text-align: center;
  }

  button{
    margin-top: 2rem;
    width: 50%;
    justify-content: center;
    min-width: auto;
  }

  @media ${device.md} {
      h1{
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }

    @media ${device.sm} {
      padding: 6rem 2rem;

      h1{
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }
`

export default function Home_Header(props) {

  const {t} = useTranslation()
  const navigate = useNavigate();
  const {lang} = useParams();
  const auth = useContext(AuthContext);

  return (
    <HomeHeader>
      <HomeHeaderWrapper>
        <HomeHeaderHero>
          <h1>{t("Run 'no-brainer' ads on")}<br/>{t("Facebook & Instagram")}<br/>{t("without marketing knowledge!")}</h1>
          <H5>{t("Ideal for small businesses, freelancers, solopreneurs & business owners.")}</H5>
          <Cta_ size="large" text={t("Start Now")}  onClick={()=> hasQuestAdSubscription(navigate, auth)}/>
        </HomeHeaderHero>
        {/* <HomeHeaderImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/One/illus.svg" /> */}
        <HomeHeaderImg src={homeAutomation} />
      </HomeHeaderWrapper>
    
    </HomeHeader>
  )
}
