import { Grey_Link, IconSvg, Main_Plain, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const EditQuestionnaire = styled.div`
  background: ${p => p.theme.low};
  border-radius: var(--smallRadius);
  border: 2px solid ${p => p.theme.low};
  grid-column: ${p => p.type === 'services' ? 'span 1' : 'span 2' };

  @media ${device.xl} {
    grid-column: span 1;
  }
`

const EditQuestionnaireTitle = styled.h5`
  padding: 1rem 2rem;
`

const EditQuestionnaireContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 4rem);
`

const EditQuestionnaireWrapper = styled.div`
  background: ${p => p.theme.background};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  height: 100%;
`

const EditQuestionnaireSection = styled.div`
  padding: 1rem 2rem;

  h6{
    margin-bottom: 1rem;
  }
`

const EditQuestionnaireList = styled.ul`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const EditQuestionnaireItem = styled.li`
  background: ${p => p.theme.low};
  padding: .5rem 1rem;
  border-radius: 1rem;
  border: 1px solid ${p => p.theme.low};

  p{
    white-space: nowrap;
  }
`

const EditQuestionnaireNo = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  background: transparent;
  padding: .5rem 1rem;
  border-radius: 1rem;
  border: 1px solid ${p => p.theme.mid};

  svg{
    fill: var(--greyDark);
    width: 1.6rem;
    margin-bottom: 5px;
  }

  p{
    color: var(--greyDark);
  }
`

const EditQuestionnaireActions = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem;
`

export default function Edit_Questionnaire(props) {

  const {t} = useTranslation()

  return (
    <EditQuestionnaire type={props.type}>
      <EditQuestionnaireTitle>{t("Campaign setup")}</EditQuestionnaireTitle>
      <EditQuestionnaireContainer>
        <EditQuestionnaireWrapper>
          <EditQuestionnaireSection>
            <h6>{t("Targeted locations")}</h6>
            <EditQuestionnaireList>
              {props.questLocations?.map((location, i) => {
                return (
                  <EditQuestionnaireItem key={i}>
                    <p>{location.name}, {location.country_name}</p>
                  </EditQuestionnaireItem>
                );
              })}
            </EditQuestionnaireList>
          </EditQuestionnaireSection>
          <EditQuestionnaireSection>
            <h6>{t("Audience Interests")}</h6>
            {props.questInterests?.length > 0 ?
              <EditQuestionnaireList>
                {props.questInterests?.map((interest, i) => {
                return (
                  <EditQuestionnaireItem key={i}>
                    <p>{interest.name}</p>
                  </EditQuestionnaireItem>
                );
              })}
              </EditQuestionnaireList>
            :
              <EditQuestionnaireNo>
                <IconSvg Icon="Tip" />
                <p>{t("No interests selected")}</p>
              </EditQuestionnaireNo>
            }
          </EditQuestionnaireSection>
          <EditQuestionnaireSection>
            <h6>{t("Targeted Audience")}</h6>
            <EditQuestionnaireList>
              <EditQuestionnaireItem>
                <p>{props.questMinAge} - {props.questMaxAge}</p>
              </EditQuestionnaireItem>
              <EditQuestionnaireItem>
                <p>{props.questGender}</p>
              </EditQuestionnaireItem>
            </EditQuestionnaireList>
          </EditQuestionnaireSection>
        </EditQuestionnaireWrapper>
        <EditQuestionnaireActions>
          <Main_Plain iconLeft="Edit" text={t("Edit")} noPadding size="small" onClick={props.onQuestEdit}/>
        </EditQuestionnaireActions>
      </EditQuestionnaireContainer>
    </EditQuestionnaire>
  )
}
