import React from 'react'
import styled from 'styled-components'
import Dashboard_Quest from './Dashboard_Quest'
import { useTranslation } from 'react-i18next'
import FbLogo from '../../../assets/dashboard/FbLogo.png';
import { prettifyDates } from '../../../helpers/prettifiers';
import { device } from 'monica-alexandria';

const DashboardActive = styled.div`
  
`

const DashboardActiveWrapper = styled.div`
  padding: 2rem;
  border-radius: 2rem;
  background: ${p => p.theme.low};
  margin-bottom: 2rem;
`

const DashboardActiveTitle = styled.h4`
  margin-bottom: 2rem;
`

const DashboardActiveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media ${device.xl} {
    grid-template-columns: 1fr;
  }
`

export default function Dashboard_Active(props) {

  const {t} = useTranslation()

  const autoRenewalQuests = props.activeQuests?.filter((quest) => !quest?.matchingSubId?.cancel_at_period_end &&  !quest?.newBudget)
  const pendingCancelationQuests = props.activeQuests?.filter((quest) => quest?.matchingSubId?.cancel_at_period_end)
  const newBudgetTopUpQuests = props.activeQuests?.filter((quest) => quest?.newBudget);

  const set_and_view_selected_quest = (quest) => {
    props.activeSetSelectedQuest(quest);
    props.activeViewSelectedQuest();
  }

  const set_and_edit_subscription = (quest) => {
    props.activeSetSelectedQuest(quest);
    props.activeEditSelectedSubscription();
  }

  return (
    <DashboardActive>
      {pendingCancelationQuests?.length ?
        <DashboardActiveWrapper>
          <DashboardActiveTitle>{t("Subject to cancellation at the end of the billing period")}</DashboardActiveTitle>
          <DashboardActiveGrid>
            {pendingCancelationQuests?.map((quest, i) => (
                <Dashboard_Quest 
                  cancelled={quest?.matchingSubId?.cancel_at_period_end}
                  // avatar={quest?.Meta?.facebookPagePicture}
                  avatar={FbLogo}
                  page={quest?.Meta?.facebookPageName}
                  type={quest?.questionnaires[0]?.answer}
                  industry={quest?.questionnaires[1]?.answer}
                  totalBudget={quest?.amountPaid / 100}
                />
            ))}
          </DashboardActiveGrid>
        </DashboardActiveWrapper>
      :
        null
      }
      {autoRenewalQuests?.length ?
        <DashboardActiveWrapper>
          <DashboardActiveTitle>{t("Subject to auto-renewal")}</DashboardActiveTitle>
          <DashboardActiveGrid>
            {autoRenewalQuests?.map((quest, i) => (
                <Dashboard_Quest 
                  cancelled={quest?.matchingSubId?.cancel_at_period_end}
                  onClick = {() => set_and_view_selected_quest(quest)}
                  // avatar={quest?.Meta?.facebookPagePicture}
                  avatar={FbLogo}
                  page={quest?.Meta?.facebookPageName}
                  type={quest?.questionnaires[0]?.answer}
                  industry={quest?.questionnaires[1]?.answer}
                  totalBudget={quest?.amountPaid / 100}
                  renewalDate={prettifyDates(quest?.matchingSubId?.current_period_end * 1000)}
                  onActiveSubscription={() => set_and_edit_subscription(quest)}
                />
            ))}
          </DashboardActiveGrid>
        </DashboardActiveWrapper>
      :
          null
      }
      {newBudgetTopUpQuests?.length ?
        <DashboardActiveWrapper>
          <DashboardActiveTitle>{t("Subject to top up on the next invoice")}</DashboardActiveTitle>
          <DashboardActiveGrid>
            {newBudgetTopUpQuests?.map((quest, i) => (
                <Dashboard_Quest 
                  // cancelled={quest?.matchingSubId?.cancel_at_period_end}
                  onClick = {() => set_and_view_selected_quest(quest)}
                  // avatar={quest?.Meta?.facebookPagePicture}
                  avatar={FbLogo}
                  page={quest?.Meta?.facebookPageName}
                  type={quest?.questionnaires[0]?.answer}
                  industry={quest?.questionnaires[1]?.answer}
                  totalBudget={quest?.newBudget / 100}
                  renewalDate={prettifyDates(quest?.matchingSubId?.current_period_end * 1000)}
                  onActiveSubscription={() => set_and_edit_subscription(quest)}
                />
            ))}
          </DashboardActiveGrid>
        </DashboardActiveWrapper>
      :
          null
      }
    </DashboardActive>
  )
}
