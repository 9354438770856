import { P, device } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'
import { H2 } from '../../../styles/components/HomeText'
import homeQuestion1 from '../../../assets/home/homeQuestion1.png'
import homeQuestion2 from '../../../assets/home/homeQuestion2.png'
import { useTranslation } from 'react-i18next'
import RunAds from '../../../assets/home/RunAds.svg'

const HomeQuestions = styled.section`
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 8rem;


    @media ${device.xl} {
        display: flex;
        flex-direction: column-reverse;
        gap: 8rem;
    }
`
const HomeQuestionsText = styled.div`
    padding-bottom: 4rem;
`

const HomeQuestionsIllu = styled.div`

    img{
        width: 100%;
        max-width: 80rem;
        margin: 0 auto;
        display: block;
    }
`


const HomeQuestionsImgs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-right: 2rem;
    align-items: flex-end;
    max-width: 100%;
   
    
    img{
        border-radius: var(--normalRadius);
        transform: translate(-2rem ,0);
        box-shadow: ${p => p.theme.inFocus};
        padding: 2px;

        &:first-of-type{
            transform: translate(2rem ,0);
            box-shadow: ${p => p.theme.outFocus};
            padding: 0;
        }
    }

    @media ${device.xl} {
        align-items: flex-start;
        margin-right: 0;
        padding: 1rem;
        transform: translate(2rem, 0);
        overflow: hidden;

        img{
            transform: translate(0 , 0) !important;
        }
    }
`
export const Home_Questions = () => {
    const {t} = useTranslation();
  return (
    <HomeQuestions>
        
        {/* <HomeQuestionsImgs>
            <img src={homeQuestion1}/>
            <img src={homeQuestion2}/>
        </HomeQuestionsImgs> */}
        <HomeQuestionsIllu>
            <img src={RunAds} />
        </HomeQuestionsIllu>
        <HomeQuestionsText>
            <H2>{t('You answer questions, we run ads!')}</H2>
            <P>{t('Just answer a few easy questions about your business, and our algorithm takes over!  Crafting automated digital marketing strategies for optimal performance depending your answers. We create campaigns, we adjust campaigns in retargeting your audience based on landing page visits and social engagement, all automatically without the need for marketing expertise by your side.')}</P>
        </HomeQuestionsText>  
    </HomeQuestions>
  )
}
