import { FormatNumbers, Main_Plain, Toggle_, device } from "monica-alexandria";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CurrentRotors = styled.div`
  padding: var(--normalPads);
  background: ${(p) => p.theme.low};
  border-radius: var(--smallRadius);
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between;
  grid-template-columns: auto auto auto;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto auto auto;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`;

const CurrentRotorsMid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  h3 {
    text-align: center;
  }

  @media ${device.lg} {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  @media ${device.md} {
    grid-template-columns: auto auto auto;
    grid-row-start: auto;
    grid-row-end: auto;
  }

  @media ${device.sm} {
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

const CurrentTime = styled.div`
  position: relative;
  height: 15rem;
  width: 15rem;
`;

const CurrentTimeOut = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 14rem;
  width: 14rem;
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.in};
`;

const CurrentTimeIn = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 12.8rem;
  width: 12.8rem;
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.out};
`;

const CurrentTimeRing = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 15rem;
  height: 15rem;

  canvas {
    height: 100%;
    width: 100%;
  }
`;

const CurrentTimeText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    color: var(--main);
    font-size: 5rem;
    line-height: 1;
    white-space: nowrap;
  }

  h5 {
    color: var(--main);
  }
`;

const CurrentSpend = styled.div`
  position: relative;
  height: 19rem;
  width: 19rem;
  margin: 0 auto;
`;

const CurrentSpendOut = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 19rem;
  width: 19rem;
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.out};
`;

const CurrentSpendIn = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 17.5rem;
  width: 17.5rem;
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.in};
`;

const CurrentSpendRing = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 20.2rem;
  height: 20.2rem;

  canvas {
    height: 100%;
    width: 100%;
  }
`;

const CurrentSpendText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    color: var(--focus);
    font-size: 4rem;
    line-height: 1;
    white-space: nowrap;
  }

  h5 {
    color: var(--focus);
  }
`;

const CurrentLeads = styled.div`
  gap: 1.5rem;
  position: relative;
  height: 19rem;
  width: 19rem;
  margin: 0 auto;
`;

const CurrentLeadsCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  border: 5px solid ${(p) => p.theme.color};
  cursor: ${(p) => (p.clickable ? "pointer" : "default")};
  transition: background 0.5s;

  &:hover {
    background: ${(p) => (p.clickable ? p.theme.color : "transparent")};

    h2,
    h5 {
      color: ${(p) => (p.clickable ? p.theme.background : p.theme.color)};
    }
  }
`;

const CurrentLeadsText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    font-size: 5rem;
    line-height: 1;
    white-space: nowrap;
  }
`;

const CurrentRotorsMidToggle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Current_Rotors(props) {
  const { t } = useTranslation();

  const INTERVAL = 30;

  const canvasTime = useRef(null);
  const canvasBudget = useRef(null);

  useEffect(() => {
    const computedStyle = window.getComputedStyle(document.documentElement);
    const progressTime = 2 * Math.PI * (props.days / INTERVAL);

    if (canvasTime.current) {
      const canvas = canvasTime.current;
      const context = canvas.getContext("2d");
      const size = canvas.width; // Assuming the canvas is square
      const center = size / 2;
      const radius = center - 5; // 10px padding for aesthetics
      const mainColor = computedStyle.getPropertyValue("--main");

      const clearCanvas = () => {
        context.clearRect(0, 0, size, size);
      };

      const drawCircle = (color) => {
        context.beginPath();
        context.arc(center, center, radius, 0, progressTime);
        context.strokeStyle = color;
        context.lineWidth = 2;
        context.stroke();
      };

      clearCanvas();

      drawCircle(mainColor, [1, 2 * Math.PI]);
    }
  }, [props.days]);

  useEffect(() => {
    const computedStyle = window.getComputedStyle(document.documentElement);
    const progressBudget =
      2 * Math.PI * (props.spentBudget / props.totalBudget);

    if (canvasBudget.current) {
      const canvas = canvasBudget.current;
      const context = canvas.getContext("2d");
      const size = canvas.width; // Assuming the canvas is square
      const center = size / 2;
      const radius = center - 5; // 10px padding for aesthetics
      const focusColor = computedStyle.getPropertyValue("--focus");

      const clearCanvas = () => {
        context.clearRect(0, 0, size, size);
      };

      const drawCircle = (color) => {
        context.beginPath();
        context.arc(center, center, radius, 0, progressBudget);
        context.strokeStyle = color;
        context.lineWidth = 2;
        context.stroke();
      };

      clearCanvas();

      drawCircle(focusColor, [1, 2 * Math.PI]);
    }
  }, [props.spentBudget, props.totalBudget]);

  return (
    <CurrentRotors>
      <CurrentSpend>
        <CurrentSpendIn></CurrentSpendIn>
        <CurrentSpendOut></CurrentSpendOut>
        <CurrentSpendRing>
          <canvas ref={canvasBudget} width="100%" height="100%" />
        </CurrentSpendRing>
        <CurrentSpendText>
          <h2>€ {props.spentBudget?.toFixed(0)}</h2>
          <h5>{t("Adspent")}</h5>
        </CurrentSpendText>
      </CurrentSpend>
      <CurrentRotorsMid>
        {props.interval ? (
          <CurrentRotorsMidToggle>
            <h3>{t("Current interval")}</h3>
            <Main_Plain
              iconLeft="Swap"
              noPadding
              size="small"
              text={t("Show all time")}
              onClick={props.onInterval}
            />
          </CurrentRotorsMidToggle>
        ) : (
          <CurrentRotorsMidToggle>
            <h3>{t("All time")}</h3>
            <Main_Plain
              iconLeft="Swap"
              noPadding
              size="small"
              text={t("Show currnet interval")}
              onClick={props.onInterval}
            />
          </CurrentRotorsMidToggle>
        )}
        <CurrentTime>
          <CurrentTimeIn></CurrentTimeIn>
          <CurrentTimeOut></CurrentTimeOut>
          <CurrentTimeRing>
            <canvas ref={canvasTime} width="100%" height="100%" />
          </CurrentTimeRing>
          <CurrentTimeText>
            <h2>{props.days}</h2>
            <h5>{t("Days")}</h5>
          </CurrentTimeText>
        </CurrentTime>
      </CurrentRotorsMid>
      {props.type === "services" ? (
        <CurrentLeads onClick={props.onLeads}>
          <CurrentLeadsCircle clickable>
            <CurrentLeadsText>
              <h2>{props.leadsOverview || "N/A"}</h2>
              <h5>{t("Leads")}</h5>
            </CurrentLeadsText>
          </CurrentLeadsCircle>
        </CurrentLeads>
      ) : props.type === "eshop" ? (
        <CurrentLeads>
          <CurrentLeadsCircle>
            <CurrentLeadsText>
              <h2>{FormatNumbers(props.pageviews) || "N/A"}</h2>
              <h5>{t("Pageviews")}</h5>
            </CurrentLeadsText>
          </CurrentLeadsCircle>
        </CurrentLeads>
      ) : (
        <CurrentLeads>
          <CurrentLeadsCircle>
            <CurrentLeadsText>
              <h2>{FormatNumbers(props.engagement) || "N/A"}</h2>
              <h5>{t("Engagement")}</h5>
            </CurrentLeadsText>
          </CurrentLeadsCircle>
        </CurrentLeads>
      )}
    </CurrentRotors>
  );
}
