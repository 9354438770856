import { Cta_, Focus_, H2, Main_, Main_Plain, P, device } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import homeLanding1 from '../../../assets/home/homeLanding1.jpg'
import homeLanding2 from '../../../assets/home/homeLanding2.jpg'
import LandingPage from '../../../assets/home/LandingPage.svg'
import Stats from '../../../assets/home/Stats.svg'
import homeAdBig from '../../../assets/home/homeAdBig.jpg'
import homeAdSmall from '../../../assets/home/homeAdSmall.jpg'
import homeAnalyticsSmall from '../../../assets/home/homeAnalyticsSmall.jpg'
import homeAnalyticsBig from '../../../assets/home/homeAnalyticsBig.jpg'
import { useNavigate, useParams } from 'react-router-dom'
import { hasQuestAdSubscription } from '../utils'
import { AuthContext } from '../../../services/authentication/Guard'

const HomeInfo = styled.section`
  

    .gradientText{
        text-align: center;
        /* background-color: #f3ec78;
        background-image: linear-gradient(to bottom right, var(--gloom), var(--main) 10%, var(--gloom));
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; */
    }
`

const HomeInfoIllu = styled.div`

    img{
        width: 100%;
    }
`

const HomeInfoBox = styled.div`
    padding: 2rem 0;
    position: relative;
    height: 55rem;
    width: 100%;
    border-radius: var(--smallRadius);
    box-shadow: ${p => p.theme.outFocus};
    background: linear-gradient(to bottom right, var(--main), var(--focusLight));
    //background: ${p => p.theme.mid};
    h5{
        padding-bottom: 1rem;
    }

    @media ${device.md} {
      overflow: hidden;
    }
`

const HomeInfoBoxSecond = styled(HomeInfoBox)`
    @media ${device.md} {
      overflow: visible;
    }
`

const HomeInfoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16rem;
    padding-bottom: 12rem;
    align-items: center;

    
    @media ${device.md}{
        grid-template-columns: 1fr;
    }
`

const HomeInfoWrapperInverse = styled(HomeInfoWrapper)`

    @media ${device.md} {
        display: flex;
        flex-direction: column-reverse;
        gap: 20rem;
    }
`

const HomeInfoImgLandingBig = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;

   img{
    position: absolute;
    /* /height: 35rem; */
    border-radius: var(--smallRadius);
    left: 15%;
    top: 4rem;
    box-shadow: ${p=> p.theme.out};
   } 

   @media ${device.md} {
    position: static;
    img{
            position: static;
        //transform: scale(.8);
        //left: 0;
        //top: 0;
    }
   }

   @media ${device.lg} {
    img{
        transform: scale(.9);
        left: 0;
        top: 5rem;
    }
   }
`

const HomeInfoImgLandingSmall = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

  

   img{

       border-radius: var(--smallRadius);
       position: absolute;
       top: 20rem;
       left: 55%;
       box-shadow: ${p=> p.theme.outFocus};
   } 

   @media ${device.lg} {
        img{
            top: 7rem;
            left: 50%;
            transform: scale(.8);
        }
    }


    @media ${device.md} {
        display: none;

    img{
        top: 12rem  ;
        //left: 40% ;
        right: 10%;
        transform: scale(.6) ;
    }
}
`

const HomeInfoImgAnalyticsBig = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;

   img{
    position: absolute;
    /* /height: 35rem; */
    border-radius: var(--smallRadius);
    right: 55%;
    top: 2rem;
    box-shadow: ${p=> p.theme.out};
   } 

   @media ${device.md} {
   
        img{
            top:0rem;
            transform: scale(.9);
            right: 45%;
           
        }
    }
    @media ${device.lg} {
        img{
            top:0rem;
            transform: scale(.9);
            right: 20%;
           
        }
    }
`

const HomeInfoImgAnalyticsSmall = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   img{
       border-radius: var(--smallRadius);
       position: absolute;
       bottom: -20rem;
       left: 13rem;
       width: 100%;
       max-width: 75rem;
       box-shadow: ${p=> p.theme.outFocus};
   } 

   @media ${device.lg} {
        img{
            bottom: -15rem;
            max-width: 50rem;
        }
    }

    @media ${device.sm} {
        img{
            bottom: -15rem;
            left: -1rem;
        }
    }
  
`


const HomeInfoImgAdBig = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;

   img{
    position: absolute;
    /* /height: 35rem; */
    border-radius: var(--smallRadius);
    right: 10%;
    top: 4rem;
    box-shadow: ${p=> p.theme.out};
   } 

   @media ${device.lg} {
        img{
            top: 4rem;
            right: 0;
            transform: scale(.9);
        }
    }
   
    @media ${device.md} {

        img{
            top: 2rem;
            transform: scale(.9);
        }
    }

   

`

const HomeInfoImgAdSmall = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   img{
       border-radius: var(--smallRadius);
       position: absolute;
       top: 25rem;
       right: 50%;
       box-shadow: ${p=> p.theme.outFocus};
   } 

    @media ${device.lg} {

        img{
            top: 25rem;
            right: 40%;
            transform: scale(.9);
        }
    }

    @media ${device.md} {

        img{
            top: 25rem ;
            left: 0rem ;
            width: 100%;
            min-width: 20rem;
            max-width: 50rem;
        }
    }

`

const HomeInfoDesc = styled.div`
    h1{
        padding-bottom: 2rem;
    }
    p{
        padding-bottom: 2rem ;
    }
`


export const Home_Info = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const auth = useContext(AuthContext);
    
  return (
    <HomeInfo>
        <H2>{t('Streamline Your Marketing with QuestAd')}</H2>
        <HomeInfoWrapper>
            {/* <HomeInfoBox>
                <HomeInfoImgLandingBig >
                    <img src={homeLanding1}/>
                </HomeInfoImgLandingBig >
                <HomeInfoImgLandingSmall>
                    <img src={homeLanding2}/>
                </HomeInfoImgLandingSmall>
            </HomeInfoBox> */}
            <HomeInfoDesc>
                <h1>{t('Landing Page Creator')}<br/>{t('with Lead Capture Form')}</h1>
                <P>{t('Effortlessly create high-converting landing pages.')}<br/>
                    {t('Integrated forms for capturing prospect leads - no design expertise required.')}</P>
                    <Focus_ text={t('Get prospect leads')} onClick={()=> hasQuestAdSubscription(navigate, auth)} noPadding large/>
            </HomeInfoDesc>
            <HomeInfoIllu>
                <img src={LandingPage} />
            </HomeInfoIllu>
        </HomeInfoWrapper>
        <HomeInfoWrapperInverse>
            <HomeInfoBoxSecond>
                <HomeInfoImgAdBig>
                    <img src={homeAdBig}/>
                </HomeInfoImgAdBig>
                {/* <HomeInfoImgAdSmall>
                    <img src={homeAdSmall}/>
                </HomeInfoImgAdSmall> */}
            </HomeInfoBoxSecond>
            <HomeInfoDesc>
                <h1>{t('No expertise needed for')}<br/>{t('Ad Creation')}</h1>
                <P>{t("Create effective ads easily with our user-friendly ad builder.")}  <br/>
                    {t('Marketing skills are not necessary, just focus on your message and leave everything to us.')}</P>
                <Focus_ text={t('Create beatiful ads')} onClick={()=> hasQuestAdSubscription(navigate, auth)} noPadding large/>
            </HomeInfoDesc>
            
        </HomeInfoWrapperInverse>
        <HomeInfoWrapper>
            {/* <HomeInfoBoxSecond>
                <HomeInfoImgAnalyticsBig>
                    <img src={homeAnalyticsBig}/>
                </HomeInfoImgAnalyticsBig>
                <HomeInfoImgAnalyticsSmall>
                    <img src={homeAnalyticsSmall}/>
                </HomeInfoImgAnalyticsSmall>
            </HomeInfoBoxSecond> */}
            <HomeInfoDesc>
                <h1>{t('Real-Time')}<br/>{t('Analytics Display')}</h1>
                <P>
                    {t('Monitor your ads in Real-Time, access your dashboard for instant analytics and performance tracking.')}<br/>
                    {t('What can you track?')}<br/>
                    {t('Reach - Monitor how far your ads are going.')}<br/>
                    {t('Clicks - See how many users are engaging with your ads.')}<br/>
                    {t('Leads - Keep tabs on the leads generated from your campaigns.')}<br/>
                    {t('Landing Page Views - Understand the performance of your landing pages.')}<br/>
                </P>
                <Focus_ text={t('Run ads on social media now')} onClick={()=> hasQuestAdSubscription(navigate, auth) } noPadding large/>
            </HomeInfoDesc>
            <HomeInfoIllu>
                <img src={Stats} />
            </HomeInfoIllu>
        </HomeInfoWrapper>
        
    </HomeInfo>
  )
}
