import { Actions_, AlertContext, Builder_, Focus_, Grey_, Input_, Main_, Main_Text_Loader } from 'monica-alexandria';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { alertMessage } from '../../../../utils/messagesAlerts';


import q  from '../../../../services/api/Quests';
const clients = require('../../../../config/index').config

const SubscriptionTopUp = styled.div``

export default function Subscription_Topup(props) {

    const {t} = useTranslation();
    const {lang} = useParams();
    const [loading,setLoading] = useState(false);
    const [newBudget, setNewBudget] = useState(props.totalBudget);
    const { alerts, setAlerts } = useContext(AlertContext);
    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });

    const handleBudgetChange = (e) => {
        // Check if the entered value is a valid number (float or integer)

        let helpers = [];
        let successes = [];

        const inputValue = e.target.value; 

        if (inputValue != '') {
            if(inputValue < props.totalBudget + 1 )  helpers.push(t('New budget must be more than current')) 
            else if (inputValue >= 50) successes.push(t('Minimum budget is 50€/month'))

            if (!Number.isInteger(Number(inputValue))) helpers.push(t('Do not include decimals')) 
            else successes.push(t('Do not include decimals'))

            if(inputValue > 10000 )  helpers.push(t('Maximum budget is 10000€/month')) 
            else if (inputValue <= 10000) successes.push(t('Maximum budget is 10000€/month'))
        }
            setNewBudget(inputValue.trim());
            setMessages({...messages, helpers: helpers, successes: successes})
      };

      const proceed_with_new_budget = () =>{
        let budgeterrors = [];  
    
        if (messages.helpers?.length !== 0) {
            budgeterrors = messages.helpers;
             setNewBudget('')
        }
        
        if (newBudget === '')  alertMessage(alerts, setAlerts, (t('Monthly budget must not be empty.')), 'Error')
        else if (newBudget < props.totalBudget + 1 || !Number.isInteger(Number(newBudget)) ) {
            setMessages({ ...messages, errors: budgeterrors, helpers: [] })
        } else {
            // call some backend function   
            setLoading(true);
            q.topUpQuestBudgetV2(props?.selectedQuestId, newBudget, props?.selectedSubId)
            .then( res => {
                console.log('res top up budget', res)
                
                if (res?.data?.code === "Q2012") {
                    setLoading(false);
                    props.onSubscriptionTopupCancel();
                    alertMessage(alerts, setAlerts, (t('New budget was set for quest.')), 'Success')
                    window.location.reload();
                }
            })
            .catch( error => {
                console.log(error)
                setLoading(false);
            })
        }
      }
      console.log("messeges", messages)

  if (loading) return <Main_Text_Loader text={t('Applying Changes...')}/>
  return (
    <SubscriptionTopUp>
        <Builder_ title={t("Set quest's budget")}></Builder_>
            <Input_ 
                placeholder={t("Quest's budget")}
                value={newBudget}
                onChange = {handleBudgetChange}
                errors={messages?.errors}
                helpers={messages?.helpers}
                successes={messages?.successes}
            />
        <Actions_>
            <Grey_ iconLeft="Xicon" text={t("Cancel")} onClick={props.onSubscriptionTopupCancel}/>
            <Focus_ iconRight="Next" text={t("Set budget")} onClick={proceed_with_new_budget}/>
        </Actions_>
    </SubscriptionTopUp>
  )
}
