import {React, useEffect, useContext, useState} from 'react'
import Home_Header from './components/Home_Header';
import styled from 'styled-components';
import { device, Main_Loader } from 'monica-alexandria';
import Home_Features from './components/Home_Features';
import { Home_Testimonials } from './components/Home_Testimonials';
import Home_AdSpend from './components/Home_AdSpend';
import { Home_Help } from './components/Home_Help';
import {Home_Info } from './components/Home_Info';
import Home_How from './components/Home_How';
import { Home_Last } from './components/Home_Last';
import Home_Qa from './components/Home_Qa';
import { Home_Footer } from './components/Home_Footer';
import { Home_Pricing } from './components/Home_Pricing';
import { Home_Questions } from './components/Home_Questions';
import q from '../../services/api/Quests';
import { AuthContext } from '../../services/authentication/Guard';
import { useNavigate } from 'react-router-dom'
import i18n from '../../localization/i18next';


const Home = styled.div`

    section {
        padding: 10rem 4rem;
    }

    h2 {
        margin-bottom: 5rem;
    }

    @media ${device.md} {
        section{
            padding: 5rem 2rem;
        }
    }
`;

const HomeContent = styled.div`
   // max-width: 60%;
   padding-top: 16rem;
    margin: 0 auto;

     @media ${device.md} {
        padding-top: 15rem;
    }
    @media ${device.lg} {
        padding-top: 10rem;
    }
`
export default function HOME() {

  const navigate = useNavigate();
  const [quests, setQuests] = useState(false)
  const [loading, setLoading] = useState(true)
  const auth = useContext(AuthContext);

  const get_my_quests = () => {
    if (auth?.authenticated) {
        q.getMyQuests()
        .then(res => {
            if (res?.data?.data?.length) {
                navigate(`/${i18n?.language}/dashboard`);
            }
            else {
                setLoading(false);
            }
        }).catch(setLoading(false))
    }
    else setLoading(false)
  }

  useEffect(() => {
    get_my_quests()
  },[])


  return (
      <> 
      {loading ? <Main_Loader />
      :<Home>
      <Home_Header />
      <HomeContent>
          <Home_Questions/>
          <Home_Info />
          <Home_Testimonials />
          <Home_AdSpend />
          <Home_Pricing/>
          <Home_Qa />
          <Home_How />
          <Home_Footer />
      </HomeContent>
  </Home>}
  </>
   
  )
}
